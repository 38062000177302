import { Navigate } from 'react-router-dom';
import { useLocalStorage } from '../../../hooks/useLocalStorage'; // Asegúrate de importar tu hook

const RutaProtegida = ({ children }) => {
  const [codigoValido] = useLocalStorage('codigoValido'); // Recupera el estado del código válido

  // Si el código no es válido, redirige a otra página
  if (!codigoValido) {
    return <Navigate to="/cliente" />; // Redirige a una página de error o login
  }

  // Si es válido, renderiza la vista protegida
  return children;
};

export default RutaProtegida;
