import React from 'react'
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  Image
} from '@react-pdf/renderer'
import logo from '../../../assets/logo-oficial.png'
import { DetalleOrden } from './DetalleOrden'
import { stylesPDF } from './stylePDF'

const styles = stylesPDF

export const PDFExample = ({ result }) => {
  const now = new Date();
  const formattedDate = now.toLocaleDateString(); // Formateo de fecha (ej. "9/16/2024")
  const formattedTime = now.toLocaleTimeString(); // Formateo de hora (ej. "10:35:00 AM")
  return (
    <PDFViewer width="100%" height="100%">
      <Document>
        <Page
          size="A4"
          style={{
            ...styles.page,
            marginTop: 20,
            paddingTop: 20,
            paddingBottom: 40
          }}
        >
          <View style={styles.section}>
            <View style={styles.container}>
            <Image
                src={logo}
                style={{ ...styles.logo, width: 70, height: 70, marginTop: -60, marginLeft: 20 }}
              />
            </View>

            <View style={{ ...styles.row, marginTop: -10 }}>
              <View style={{ ...styles.column, marginTop: +20 }}>
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 9,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >
                  Cliente:
                </Text>
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 9,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >
                  Fecha de Inicio Reclamo:
                </Text>
                ,
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 9,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >
                  Telefono:
                </Text>
                <Text
                  style={{
                    ...styles.content,
                    fontSize: 9,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginTop: 2,
                    marginLeft: 20
                  }}
                >
                  Observaciones
                </Text>
                <View
                  style={{
                    padding: 1,
                    fontWeight: 'bold',
                    maxWidth: '90%',
                    borderRadius: 5,
                    borderWidth: 1,
                    borderColor: '#000',
                    height: 25,
                    marginTop: 2,
                    marginLeft: 20
                  }}
                >
                  <Text
                    style={{
                      ...styles.content,
                      fontSize: 9,
                      marginLeft: 10,
                      marginRight: 0,
                      paddingRight: 0,
                      inlineSize: '50px',
                      overflowWrap: 'break-word',
                      maxWidth: 275,
                      maxHeight: 275
                    }}
                  >
                    {/* {result.produccion.obsProd} */}
                  </Text>
                </View>
              </View>

              <View style={{ ...styles.row, marginTop: -40 }}>
                <View style={styles.column}>
                  <Text
                    style={{
                      ...styles.content,
                      fontWeight: 'bold',
                      borderRadius: 5,
                      fontSize: 14,
                      marginBottom: 1,
                      backgroundColor: '#d8dbe3',
                      padding: 5,
                      marginRight: 20
                    }}
                  >
                    RECLAMO U OBSERVACIONES
                  </Text>
                  <View
                    style={{
                      ...styles.row,
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Text
                      style={{
                        ...styles.gridContent,
                        marginLeft: 50,
                        marginTop: 10
                      }}
                    >

                    </Text>
                  </View>

                  <View
                    style={{
                      ...styles.sectionWithBorder,
                      marginTop: 10,
                      backgroundColor: '#d8dbe3',
                      width: 220,
                      height: 89,
                      borderRadius: 5,
                      marginRight: 20
                    }}
                  >
                    <Text
                      style={{
                        ...styles.content,
                        marginLeft: 10,
                        marginTop: 7,
                        maxWidth: '100%'
                      }}
                    >
                      Factura:
                    </Text>

                    <Text
                      style={{
                        ...styles.content,
                        marginLeft: 10,
                        marginTop: 4,
                        maxWidth: '100%'
                      }}
                    >
                      Guia:
                    </Text>
                    <Text
                      style={{
                        ...styles.content,
                        marginLeft: 10,
                        marginTop: 4,
                        maxWidth: '100%'
                      }}
                    >
                      Fecha de compra:
                    </Text>

                  </View>

                  <Text
                    style={{
                      ...styles.content,
                      marginLeft: 130,
                      marginTop: -10,
                      maxWidth: '100%',
                      fontSize: 5
                    }}
                  >
                    Fecha de Creación:
                  </Text>
                </View>
              </View>
            </View>

            {<DetalleOrden />}
            {/* <View style={{ ...styles.row, flexDirection: 'row', marginTop: 10 }}>
              <View style={styles.column}>
                <Text
                  style={{
                    ...styles.content,
                    fontSize: 10,
                    maxWidth: '100%',
                    marginBottom: 2,
                    marginLeft: 20,
                    fontWeight: 'bold',
                  }}
                >
                  <Text>Estado de la evaluación:</Text>{' '}
                  {result.informacion_calidad.desEntCalEst}
                </Text>

              </View>
            </View> */}
            <View style={{ ...styles.row, flexDirection: 'row', marginTop: 10 }}>
            <View style={styles.column}>
                <Text
                  style={{
                    ...styles.content,
                    fontSize: 9,
                    minWidth: '60%',
                    marginBottom: 2,
                    marginTop: 2,
                    marginLeft: 20
                  }}
                >
                  Importante
                </Text>
                <View
                  style={{
                    padding: 1,
                    fontWeight: 'bold',
                    maxWidth: '92.8%',
                    borderRadius: 5,
                    borderWidth: 1,
                    borderColor: '#000',
                    height: 50,
                    marginTop: 2,
                    marginLeft: 20
                  }}
                >
                  <Text
                    style={{
                      ...styles.content,
                      fontSize: 9,
                      marginLeft: 10,
                      marginRight: 0,
                      paddingRight: 0,
                      inlineSize: '50px',
                      overflowWrap: 'break-word',
                      maxWidth: 488,
                      maxHeight: 275
                    }}
                  >
                    Se le hace recuerdo que dispone de 15 días como máximo para hacer llegar su producto, para su evaluación respectiva y su tratativa en caso proceda el reclamo. Pasado los 15 dias de no cumplirse el envió solicitado, no procederá su solicitud.
                    Cualquier duda o consulta dirigirse a : atencionalcliente@emaransac.com - cel. 932657880
                  </Text>
                </View>
              </View>
            </View>

          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}
