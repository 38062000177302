import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import { stylesPDF } from './stylePDF'
import { _parseInt } from '../../../utils/functions/ParseInt'
const styles = stylesPDF

export const DetalleOrden = ({  }) => {
  // importamos los estilos
  return (
    <>
      <View>
        <Text
          style={{
            ...styles.title,
            fontWeight: 'bold',
            fontSize: 7,
            marginLeft: -450,
            marginTop: -2
          }}
        >
          Destalle de Solicitud
        </Text>
        <View style={{ ...styles.section, marginTop: -25 }}>
          <View style={styles.gridContainer}>
            <View style={[styles.gridHeader, styles.greenBackground]}>
              <Text style={{ ...styles.gridTitle, flex: 0.7 }}> N°</Text>
              <Text
                style={{
                  ...styles.gridTitle,
                  flex: 4,
                  textAlign: 'center'
                }}
              >
                Descripción de Item
              </Text>
              <Text style={styles.gridTitle}>U.M</Text>
              <Text style={styles.gridTitle}>Cantidad Observada</Text>
            </View>
          </View>
        </View>

        <Text
          style={{
            ...styles.title,
            fontWeight: 'bold',
            fontSize: 7,
            marginLeft: -455,
            marginTop: -12
          }}
        >
          Evidencias(fotos)
        </Text>
        {/* <View style={{ ...styles.section, marginTop: -25 }}>
          <View style={styles.gridContainer}>
            <View style={[styles.gridHeader, styles.green_]}>
              <Text style={{ ...styles.gridTitle, flex: 0.7 }}> Cód Aso</Text>
              <Text style={{ ...styles.gridTitle, flex: 0.7 }}>Cód Ref</Text>
              <Text
                style={{
                  flex: 1,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: 7,
                  // border: "1px solid black",
                  maxWidth: '40px'
                }}
              >
                Código
              </Text>
              <Text
                style={{
                  ...styles.gridTitle,
                  flex: 4,
                  textAlign: 'center'
                  // border: "1px solid black",
                }}
              >
                Descripción de Item
              </Text>
              <Text
                style={{
                  flex: 1,
                  textAlign: 'center',
                  fontSize: 7,
                  maxWidth: '30px'
                  // border: "1px solid black",
                }}
              >
                U.M
              </Text>
              <Text
                style={{
                  flex: 1,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: 7,
                  // border: "1px solid black",
                  maxWidth: '40px'
                }}
              >
                Cantidad
              </Text>
              <Text
                style={{
                  flex: 1,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: 7,
                  // border: "1px solid black",
                  maxWidth: '40px'
                }}
              >
                Total
              </Text>
            </View>
            
          </View>
        </View> */}
      </View>
    </>
  )
}
