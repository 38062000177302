import axios from 'axios'
import config from './../../../config'

export const validar_codigo = async (body) => {
  const domain = config.API_URL
  const path = '/cliente/validacion/validar_codigo.php'
  const url = domain + path
  const { data } = await axios.post(url, {
    ...body
  })

  return data
}
