import axios from 'axios'
import config from '../../../config'

export const createProduccionLoteWithRequisiciones2 = async (body) => {
  const domain = config.API_URL
  const path =
    '/produccion/produccion_lote_procesos/create_produccion_lote_procesos.php'
  const url = domain + path

  const { data } = await axios.post(url, {
    ...body
  })

  return data
}
