import { CreateOperacionDevolucionV2 } from './FormularioOperacionDevolucionV2'
import { CreateOperacionDevolucion } from './FormularioOperacionDevolucion'
import { ListOperacionDevolucionVentas } from './ListOperacionDevolucion'

export const RouterOperacionDevolucion = [
  {
    path: '',
    element: <CreateOperacionDevolucionV2 />
  }
  // {
  //   path: 'crear',
  //   element: <CreateOperacionDevolucionV2 />
  // }
]
