import axios from 'axios'
import config from '../../../config'

export const getInfoSolicitudOperacionDevolucion = async (idEntSto) => {
  const { API_URL } = config
  const domain = API_URL
  const path = '/calidad/operacion-solicitud-reclamo/viewSolicitudReclamo.php'
  const url = domain + path
  const { data } = await axios.post(url, {
    id: idEntSto
  })
  return data
}
