import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { TextField } from '@mui/material'
// table
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

const ITEM_HEIGHT = 48
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}))

export const ComponentActionProcesoSeleccion = ({
    onUpdateDetalleRequisicion,
    onDeleteDetalleRequisicion,
    onCreateSalidaTotal,
    onCreateSalidaParcial,
    onTerminarSalidaParcial,
    detalle,
    row
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    // 1. Pendiente
    // 2. Completo
    // 3. En proceso

    return (
        <div className="btn-toolbar">
            {/* Menu options de otras opciones */}
            <div>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button'
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        }
                    }}
                >
                    {/* desce aca se puede solucionar con un menuItem hay que ver cual es mejor */}
                    {/* Menu de actualizar requisicion */}
                    <MenuItem onClick={() => console.log('el row es:', row)}>
                        Ver
                    </MenuItem>

                    {/* Menu de eliminar requisicion */}
                    <MenuItem
                    >
                        Eliminar
                    </MenuItem>
                </Menu>
            </div>
        </div>
    )
}
