import { RouterAlmacenCalidad } from '../pages/almacen-calidad/RouterAlmacenCalidad'
import { RouterAtributoCalidad } from '../pages/atributos-calidad/RouterAtributoCalidad'
import { RouterAlmacenEntradaStock } from '../pages/entradasStock/RouterAlmacenEntradas'
import { RouterOperacionDevolucionCalidad } from '../pages/operacion-reproceso-calidad/RouterOperacionDevolucionCalidad'
import { RouterReportesCalidad } from '../pages/reportes/RouterReportesCalidad'
import LayoutModulo from './../../layout/LayoutModulo'
import HomeCalidad from './../pages/HomeCalidad'
import { RouterRequisionGeneralMateriales } from '../../general/pages/requisicion_general_materiales/RouterRequisionGeneralMateriales'
import { RouterOperacionReprocesoMasivo } from '../pages/operacion-reproceso-masivo/RouterOperacionReprocesoMasivo'
import { RouterLoteProduccion } from '../pages/produccion-lote/RouterLoteProduccion'
import { RouterOperacionDevolucion } from '../pages/operacion-devolucion/RouterOperacionDevolucion'
// PROTECCION DE RUTAS
import ProtectedRoute from '../components/componentes-auth/ProtectedRoute'

export const RouterCalidad = [
  {
    path: '',
    element: <HomeCalidad />
  },
  {
    path: 'entradas-stock',
    element: <LayoutModulo />,
    children: RouterAlmacenEntradaStock
  },
  {
    path: 'atributos-calidad',
    element: (
      <ProtectedRoute>
        <LayoutModulo />
      </ProtectedRoute>
    ),
    children: RouterAtributoCalidad
  },
  {
    path: 'almacen',
    element: <LayoutModulo />,
    children: RouterAlmacenCalidad
  },
  {
    path: 'reportes-calidad',
    element: (
      <ProtectedRoute>
        <LayoutModulo />
      </ProtectedRoute>
    ),
    children: RouterReportesCalidad
  },
  {
    path: 'operacion-devolucion',
    element: (
      <ProtectedRoute>
        <LayoutModulo />
      </ProtectedRoute>
    ),
    children: RouterOperacionDevolucionCalidad
  },
  {
    path: 'requisicion-general',
    element: (
      <ProtectedRoute>
        <LayoutModulo />
      </ProtectedRoute>
    ),
    children: RouterRequisionGeneralMateriales
  },
  {
    path: 'requisicion-devolucion-masiva',
    element: (
      <ProtectedRoute>
        <LayoutModulo />
      </ProtectedRoute>
    ),
    children: RouterOperacionReprocesoMasivo
  },
  {
    path: 'produccion-lote',
    element: (
      <ProtectedRoute>
        <LayoutModulo />
      </ProtectedRoute>
    ),
    children: RouterLoteProduccion
  },
  ,
  {
    path: 'operacion-devolucion-solicitud',
    element: <LayoutModulo />,
    children: RouterOperacionDevolucion
  }
]
