import axios from 'axios'
import config from '../.././../config'

export const updateRequisicionMolienda = async (body, inputValue) => {
  const domain = config.API_URL
  const path = '/molienda/requisicion/update_requisicion_Molienda.php'
  const url = domain + path

  const { data } = await axios.put(url, {
    ...body,
    canReqEmpPromDetNew: inputValue
  })
  return data
}
