import { AgregarRequisicionMolienda } from './AgregarRequisicionMolienda'
import { ListRequisicionesMolienda } from './ListRequisicionesMolienda'
export const RouterDeshidratadoRequisicion = [
  {
    path: '',
    element: <ListRequisicionesMolienda />
  },
  {
    path: 'crear',
    element: <AgregarRequisicionMolienda />
  }
]
