// import { styled } from '@mui/material/styles'
// import { Dialog } from '@mui/material'

// export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2)
//   },
//   '& .MuiDialogActions-root': {
//     padding: theme.spacing(1)
//   }
// }))
import { styled } from '@mui/material/styles';
import { Dialog } from '@mui/material';

export const BootstrapDialog2 = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    minWidth: '300px', // Ancho mínimo
    minHeight: '300px'  // Alto mínimo
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiPaper-root': {
    maxWidth: '700px', // Máximo ancho del diálogo
    width: '100%',     // Ocupa el 100% del ancho disponible
    minHeight: '100px' // Alto mínimo del diálogo
  }
}));
