import React, { useState, useEffect } from 'react';
import { FilterAlmacenDynamic } from '../../../components/ReferencialesFilters/Almacen/FilterAlmacenDynamic';
import config from '../../../config';
import axios from 'axios';
import { FilterClaseDynamic } from '../../../components/ReferencialesFilters/Clase/FilterClaseDynamic';

export const ReporteStockTotal = () => {
  const [filterData, setFilterData] = useState({
    clase: 0,
    almacen: 0,
  })

  const { clase, almacen } = filterData;

  const [isAuthorized, setIsAuthorized] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const verifyAdminUser = () => {
      const user = JSON.parse(localStorage.getItem('user'))
      // console.log('User:', user);
      if (user && user.idRolUsu === 1) {
        setIsAuthorized(true)
      } else {
        setIsAuthorized(false)
      }
      setIsLoading(false)
    };

    verifyAdminUser()
  }, [])

  useEffect(() => {
    // console.log('Is Authorized:', isAuthorized);
  }, [isAuthorized]);

  const handleClase = ({ id }) => {
    setFilterData({
      ...filterData,
      clase: id,
    })
  }

  const handleAlmacen = ({ id }) => {
    setFilterData({
      ...filterData,
      almacen: id,
    })
  }

  const submitDataFilterToExcel = async () => {
    const errors = [];
    if (almacen === 0) {
      errors.push('Debes seleccionar un almacen')
    }

    if (errors.length === 0) {
      // console.log(filterData);
      exportarReporte()
    } else {
      const handleErrors = errors.join('\n')
      alert(handleErrors)
    }
  }

  const exportarReporte = () => {
    const domain = config.API_URL
    const path = '/almacen/reportes/reporte-stock-total.php'
    axios({
      url: domain + path,
      data: filterData,
      method: 'POST',
      responseType: 'blob',
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const a = document.createElement('a')
        a.href = url
        a.download = 'reporte-stock-total.xlsx'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      })
      .catch((error) => alert('Error al descargar el archivo', error))
  };

  return (
    <div className="container-fluid mx-3">
      <div className="row mt-3">
        <div className="col-4">
          <label className="form-label">Categoria</label>
          <FilterClaseDynamic onNewInput={handleClase} defaultValue={clase} />
        </div>
        <div className="col-3">
          <label className="form-label">Almacen</label>
          {!isLoading && (
            isAuthorized ? (
              <FilterAlmacenDynamic
                onNewInput={handleAlmacen}
                defaultValue={almacen}
              />
            ) : (
              <FilterAlmacenDynamic
                onNewInput={handleAlmacen}
                defaultValue={almacen}
                onlyData={[1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12]}
              />
            )
          )}
        </div>
      </div>
      <div className="row d-flex flex-row justify-content-center mt-4">
        <p className="text-bg-light p-3 fs-4 fw-bold">
          Formatos para exportar
        </p>
        <div className="col-1">
          <button onClick={submitDataFilterToExcel} className="btn btn-success">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-file-earmark-excel-fill"
              viewBox="0 0 16 16"
            >
              <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68 8 9.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 10l2.233 2.68a.5.5 0 0 1-.768.64L8 10.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 10 5.116 7.32a.5.5 0 1 1 .768-.64z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}
