import React, { useEffect, useState } from 'react';
// IMPORTACIONES PARA TABLE MUI
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
// IMPORTACIONES PARA EL FEEDBACK
import MuiAlert from '@mui/material/Alert'
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { TextField, IconButton, Checkbox } from '@mui/material';
// VARIBLES DE AUTENTIFICACION Y REDIRECCION
import { useLocalStorage } from '../../../hooks/useLocalStorage'
// HOOKS DE DETALLE DE CONSULTA FACTURA
import { getInformacionFactura } from '../../helpers/solicitud-devolucion/getInformacionFactura'
// IMAGENES DE PASOS DE FORMULARIO
import logo from '../../../assets/logo-oficial.png'
import paso1 from './images/serie-numero.png'
import paso2_2 from './images/paso1_1.png'
import paso2_1 from './images/paso1_2.png'
import paso3_1 from './images/paso3_1.png'
import paso3_2 from './images/paso3_2.png'
import paso3_3 from './images/paso3_3.png'
// HELPERS PARA ENVIO DE DATOS AL BACKEND
import { createSolicitudDevolucion } from '../../helpers/solicitud-devolucion/createSolicitudDevolucion'
import ReCAPTCHA from "react-google-recaptcha"
import {
  FormatDateTimeMYSQLNow,
  FormatDateTimeMYSQLNowPlusYears
} from '../../../utils/functions/FormatDate'

// CONFIGURACION DE FEEDBACK
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export const CreateOperacionDevolucionV2 = () => {
  const [fechaActual, setFechaActual] = useState('')
  const [fechaVencimiento, setfechaVencimiento] = useState('')

  useEffect(() => {
    const sumarDias = (fecha, dias) => {
      const nuevaFecha = new Date(fecha)
      nuevaFecha.setDate(nuevaFecha.getDate() + dias)
      return nuevaFecha.toISOString().split('T')[0] // Formato YYYY-MM-DD
    }

    const fechaHoy = new Date();
    const fechaCon15Dias = sumarDias(fechaHoy, 15)
    setfechaVencimiento(fechaCon15Dias)
  }, [])
  const [modoEdicion, setModoEdicion] = useState(null)
  const [showModal, setShowModal] = useState(true) // Inicialmente, el modal se muestra

  const [invoiceSerie, setInvoiceSerie] = useState('') // Estado para la serie de factura
  const [invoiceNumber, setInvoiceNumber] = useState('') // Estado para el número de factura
  const [cliente, setCliente] = useState('') // Estado para almacenar el nombre del cliente
  const [loading, setLoading] = useState(false) // Estado para manejar la carga
  const [error, setError] = useState(''); // Estado para almacenar los errores
  const [detalles, setDetalles] = useState([]) // Inicializa el estado
  const [guiaRemi, setguiaRemi] = useState('') // Estado para la serie de factura
  const [id_cliente, setid_cliente] = useState('') // Estado para la serie de factura
  const [telefono, settelefono] = useState('') // Estado para la serie de factura
  const [observaciones, setobservaciones] = useState('') // Estado para la serie de factura
  const [idRefGui, setidRefGui] = useState('') // Estado para la serie de factura
  const [codigoCliente, setCodigoCliente] = useState('')
  // mane
  const [errorMessage, setErrorMessage] = useState('');
  //Estado para el control del captcha
  const [captchaValido, setCaptchaValido] = useState(false);

  const handleCaptcha = (value) => {
    if (value) {
      setCaptchaValido(true)
    }
  }
  const handleBuscar = async () => {
    // Validar que los campos no estén vacíos
    if (!invoiceSerie || !invoiceNumber) {
      setError('Por favor ingrese la serie y número de la factura')
      return;
    }

    setLoading(true); // Establece el estado de carga en verdadero
    setError(''); // Resetea cualquier mensaje de error

    try {
      const data = { invoice_serie: invoiceSerie, invoice_number: invoiceNumber }
      const codigo = JSON.parse(localStorage.getItem('codigoCliente'))
      // console.log(codigo)
      // Llama a la función para obtener los datos de la factura
      const response = await getInformacionFactura(data)

      // Verificar si la respuesta contiene datos válidos
      if (response.result && response.result.sale_details) {
        // Mapea los detalles de la venta para agregar cantidadDevo
        const detallesConCantidadDevo = response.result.sale_details.map(detalle => ({
          ...detalle,
          cantidadDevo: 0, // Valor inicial de cantidadDevo
        }));
        setid_cliente(response.result.sale.customer_id)
        setguiaRemi(response.result.sale.referral_guide)
        setidRefGui(response.result.sale.idRefGui)
        setCodigoCliente(codigo)
        setDetalles(detallesConCantidadDevo); // Establece el estado con los detalles obtenidos
      } else {
        setDetalles([]); // Establece un arreglo vacío si no hay detalles
      }

      if (response.result && response.result.sale) {
        if (response.result.sale.company != '') {
          setCliente(response.result.sale.company) // Establece el nombre del cliente
        } else {
          setCliente(response.result.sale.firstname + ' ' + response.result.sale.lastname)
        }
      } else {
        setCliente('No se encontró el cliente')
      }
    } catch (error) {
      setError('Error al buscar la factura') // Maneja cualquier error de la solicitud
    } finally {
      setLoading(false); // Establece el estado de carga en falso al finalizar
    }

  }
  useEffect(() => {
    const fecha = new Date();
    const fechaFormateada = fecha.toISOString().slice(0, 16)
    setFechaActual(fechaFormateada)
  }, [])
  // Constante para el modo de edicion para la cantidad observada
  const handleEditClick = (index) => {
    setModoEdicion(index)
  }
  // hace que no se pueda editar la cantidad observada
  const handleSaveClick = (index) => {
    const detalle = detalles[index];
    if (detalle.cantidadDevo > detalle.quantity) {
      setErrorMessage(`La cantidad observada no puede ser mayor que la cantidad comprada.`)
    } else {
      setModoEdicion(null)
      setErrorMessage('')
    }
  }
  // manejo de envio de detalles para llenar la base de datos
  const handleCantidadChange = (index, valor) => {
    const nuevosDetalles = [...detalles] // Crea una copia del array detalles

    // Asegúrate de que el valor sea un número
    nuevosDetalles[index].cantidadDevo = Number(valor);

    setDetalles(nuevosDetalles) // Actualiza el estado con los nuevos detalles
  };
  const [files, setFiles] = useState([])

  // Manejar el cambio de archivos
  const handleFileChange = (event) => {
    // Obtener los archivos seleccionados
    const newFiles = Array.from(event.target.files)
    // Combinar los archivos anteriores con los nuevos
    setFiles((prevFiles) => [...prevFiles, ...newFiles])
  }
  // manejo de eliminacion de archivos
  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)) // Eliminar el archivo en la posición `index`
  }
  // useEffect para limpiar el localStorage cuando el usuario cierra la ventana o retrocede
  useEffect(() => {
    const handleBeforeUnload = () => {
      // Limpiar el localStorage
      window.localStorage.removeItem('codigoValido')
    }

    // Agregar el listener para el evento 'beforeunload'
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Limpiar el listener cuando el componente se desmonte (cleanup)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [])

  const handleCheckboxChange = (index, isChecked) => {
    const updatedDetalles = [...detalles] // Crea una copia del array detalles
    updatedDetalles[index].isSelected = isChecked // Actualiza el estado de selección
    setDetalles(updatedDetalles) // Actualiza el estado

    // Filtra los detalles seleccionados
    const seleccionados = updatedDetalles.filter(detalle => detalle.isSelected);
  }

  // Función que cierra el modal
  const handleCloseModal = () => {
    setShowModal(false) // Cerrar el modal
  }
  // head sumit sirve para poder enviar los furmularios
  const handleSubmit = async (e) => {
    e.preventDefault() // Evita que el formulario se recargue
    setLoading(true)
    if (!captchaValido) {
      alert("Por favor, completa el CAPTCHA.")
    } else {

      // Preparar los datos para enviar
      const formData = new FormData()
      formData.append('invoiceSerie', invoiceSerie)
      formData.append('invoiceNumber', invoiceNumber)
      formData.append('cliente', cliente)
      formData.append('telefono', telefono)
      formData.append('idCliente', id_cliente)
      formData.append('guiaRemision', guiaRemi)
      formData.append('observaciones', observaciones)
      formData.append('idRefGui', idRefGui)
      formData.append('fechaActual', fechaActual)
      formData.append('detalles', JSON.stringify(detalles)) // Convertir detalles a JSON
      formData.append('codigoCliente', codigoCliente)
      files.forEach((file) => formData.append('files[]', file))

      try {

        const response = await createSolicitudDevolucion(formData)
        // Procesar la respuesta
        alert('Formulario enviado correctamente')
      } catch (err) {
        // Muestra el error de manera más descriptiva
        setError(
          err.response?.data?.message || 'Hubo un error al enviar el formulario'
        );
      } finally {
        setLoading(false);
      }
    }

  }
  const [currentPage, setCurrentPage] = useState(0);

  const pages = [
    {
      title: "Paso 1: Información General",
      content: (
        <>
          <p>Bienvenido al instructivo para rellenar el formulario.</p>
          <p>Asegúrate de tener toda la información necesaria antes de comenzar.</p>
          <p>Ingrese los datos de la boleta y presione en buscar.</p>
          <img src={paso1}
                  alt="Logo"
                  width="400"
                  height="200"
                  className="d-inline-block align-text-top" />
        </>
      )
    },
    {
      title: "PASO 2: Selección de Productos",
      content: (
        <>
          <p></p>
          <ul>
            <li>Primero marque los productos observadosen la siguiente casilla: <img src={paso2_1}
                  alt="Logo"
                  width="300"
                  height="90"
                  className="d-inline-block align-text-top"/></li>
            <li>Despues haga click en el "Lapiz" y se le activara la edicion de cantidad: <img src={paso2_2}
                  alt="Logo"
                  width="400"
                  height="90"
                  className="d-inline-block align-text-top"/></li>
            <li>Cuando termine de editar la cantidad observada, haga click en el check:</li>
          </ul>
        </>
      )
    },
    {
      title: "PASO 3: Completar observaciones y evidencias",
      content: (
        <>
          {/* <p>Describe tu reclamación con la mayor claridad posible:</p> */}
          <ul>
            <li>Ingrese sus observaciones detalladas en la siguiente sección.
            <img src={paso3_1}
                  alt="Logo"
                  width="250"
                  height="90"
                  className="d-inline-block align-text-top"/>
            </li>
            <li>Seleccione las images que considera relevantes(estas deben de ser claras).
            <img src={paso3_2}
                  alt="Logo"
                  width="350"
                  height="200"
                  className="d-inline-block align-text-top"/>
            </li>
          </ul>
        </>
      )
    },
    {
      title: "Página 4: Revisión y Envío",
      content: (
        <>
          <p>Antes de enviar el formulario:</p>
          <ul>
            <li>Revisa toda la información ingresada y marca el código reCAPTCHA.
            <img src={paso3_3}
                  alt="Logo"
                  width="250"
                  height="90"
                  className="d-inline-block align-text-top"/>
            </li>
            <li>Si todo está correcto, haz clic en "Enviar".</li>
          </ul>
        </>
      )
    }
  ];

  const handleNextPage = () => {
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      {/* hay que consultar si es necesario de cambiar el formulario que se esta proponiendo */}
      <div className="container mx-auto mt-6" style={{ maxWidth: '800px' }}>
        {showModal && (
          <div style={modalStyles}>
            <div style={modalContentStyles}>
              {/* Botón de cerrar en la esquina */}
              <button style={closeButtonStyles} onClick={handleCloseModal}>✖</button>

              <h2>{pages[currentPage].title}</h2>
              {pages[currentPage].content}

              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <button style={buttonStyles} className="btn btn-secondary" onClick={handlePreviousPage} disabled={currentPage === 0}>
                  Anterior
                </button>
                {currentPage < pages.length - 1 ? (
                  <button style={buttonStyles} className="btn btn-success" onClick={handleNextPage}>
                    Siguiente
                  </button>
                ) : (
                  <button style={buttonStyles} className="btn btn-danger" onClick={handleCloseModal}>
                    Cerrar
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {/* {showModal && (
          <div style={modalStyles}>
            <div style={modalContentStyles}>
              <h2><b>AVISO IMPORTANTE</b></h2>
              <p>
                Su reclamo/solicitud cambio fue registrado satisfactoriamente. Se le hace recuerdo que dispone de 15 días como máximo para hacer llegar su producto, para su evaluación respectiva y su tratativa en caso proceda el reclamo.
                Pasado los 15 días de no cumplirse el envío solicitado, no procederá su solicitud.
                <br />
                Fecha de límite de envío del producto observado: <b>{fechaVencimiento}</b>.
                Cualquier duda o consulta dirigirse a: <b>
                  <a href="mailto:atencionalcliente@emaransac.com?subject=Consulta&body=Escribe tu mensaje aquí" style={{ color: 'inherit', textDecoration: 'none' }}>atencionalcliente@emaransac.com</a>
                </b> - cel. <b>
                  <a href="https://wa.me/932657880" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>932657880</a>
                </b>.
              </p>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <button className="form-control btn btn-secondary" onClick={handleCloseModal} style={{ maxWidth: '100px' }}>Cancelar</button>
                <button className="form-control btn btn-success" onClick={handleCloseModal} style={{ maxWidth: '100px' }}>Aceptar</button>
              </div>
            </div>
          </div>
        )} */}


        <div className="mt-6">
          <div className="card">
            <div className="card-body row ">
              <div className="col-md-2">
                <img
                  src={logo}
                  alt="Logo"
                  width="100"
                  height="90"
                  className="d-inline-block align-text-top"
                />
              </div>
              <div className="col-md-10">
                <h2 className="text-center">Formulario de Reclamo u observaciones del cliente</h2>
              </div>
            </div>
          </div>
        </div>
        {/* DATOS DE LA REQUISICION */}
        <div className="mt-6">
          <div className="card">

            <h6 className="card-header mt-6">Datos Generales</h6>
            <div className="card-body">
              <form className="row mb-4 mt-4">
                <div className="mb-3 row">
                  {/* Campo para la serie de factura */}
                  <div className="col-md-5">
                    <label htmlFor="invoiceSerie" className="form-label">
                      <b>Serie Factura</b>
                    </label>
                    <input
                      type="text"
                      name="invoiceSerie"
                      className="form-control"
                      placeholder="F0..."
                      value={invoiceSerie} // Enlaza con el estado `invoiceSerie`
                      onChange={(e) => setInvoiceSerie(e.target.value)} // Actualiza el estado
                    />
                  </div>

                  {/* Campo para el número de factura */}
                  <div className="col-md-5">
                    <label htmlFor="invoiceNumber" className="form-label">
                      <b>Numero Factura</b>
                    </label>
                    <input
                      type="number"
                      name="invoiceNumber"
                      className="form-control"
                      placeholder="123..."
                      value={invoiceNumber} // Enlaza con el estado `invoiceNumber`
                      onChange={(e) => setInvoiceNumber(e.target.value)} // Actualiza el estado
                    />
                  </div>

                  {/* Botón de búsqueda */}
                  <div className="mb-3 col-md-2">
                    <button
                      title="Buscar"
                      type="button" // Cambia el tipo a "button"
                      className="form-control btn btn-success"
                      style={{ marginTop: '30px' }}
                      onClick={handleBuscar} // Llama a la función de búsqueda al hacer clic
                      disabled={loading} // Deshabilita el botón si está cargando
                    >
                      {loading ? 'Buscando..' : 'Buscar'}
                    </button>
                  </div>

                  {/* Campo para mostrar el cliente */}
                  <div className="col-md-12">
                    <label htmlFor="cliente" className="form-label">
                      <b>Cliente</b>
                    </label>
                    <input
                      type="text"
                      name="cliente"
                      className="form-control"
                      value={cliente} // Muestra el valor de `cliente`
                      readOnly
                    />
                  </div>

                  {/* Mostrar errores si existen */}
                  {error && (
                    <div className="col-md-12">
                      <p style={{ color: 'red' }}>{error}</p>
                    </div>
                  )}
                </div>
                <div className="mb-3 row d-flex align-items-center">
                  <div className="col-md-12">
                    <label htmlFor="nombre" className="form-label">
                      <b>Telefono</b>
                    </label>
                    <input type="number"
                      className="form-control"
                      value={telefono}
                      onChange={(e) => settelefono(e.target.value)} />
                  </div>
                  <div className="col-md-12 me-8">
                    <label htmlFor="nombre" className="form-label">
                      <b>Fecha de reclamo</b>
                    </label>
                    <br />
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={fechaActual}
                      readOnly
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="card">
            <h6 className="card-header">
              Detalle del reclamo
            </h6>
            <div className="card-body">
              <form className="row mb-4 mt-4" encType="multipart/form-data">
                {/* AGREGAR MATERIA PRIMA */}
                <div className="col-md-12">
                  <div className="mb-3 row">
                    <label className="col-md-12 col-form-label"><b>Seleccione los productos observados e ingrese la cantidad:</b>(Haga click en el Lapiz para poden ingresar la cantidad observada)</label>
                    <div className="col-md-12">
                      <div className="card-body">
                        <Paper>
                          <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                              <TableHead>
                                <TableRow
                                  sx={{
                                    '& th': {
                                      color: 'rgba(96, 96, 96)',
                                      backgroundColor: '#f5f5f5'
                                    }
                                  }}
                                >
                                  <TableCell align="left" width={10}>
                                    <b>Seleccione</b>
                                  </TableCell>
                                  <TableCell align="left" width={280}>
                                    <b>Nombre</b>
                                  </TableCell>
                                  <TableCell align="left" width={20}>
                                    <b>U.M</b>
                                  </TableCell>
                                  <TableCell align="left" width={120}>
                                    <b>Cantidad Comprada</b>
                                  </TableCell>
                                  <TableCell align="left" width={120}>
                                    <b>Cantidad Observada</b>
                                  </TableCell>
                                  <TableCell align="left" width={120}>
                                    <b>Acciones</b>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {detalles.map((detalle, index) => (
                                  <TableRow key={index}>
                                    <TableCell align="center">
                                      <Checkbox
                                        checked={detalle.isSelected || false} // Cambia este valor según cómo manejes la selección
                                        onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                                      />
                                    </TableCell>
                                    <TableCell align="left">{detalle.name}</TableCell>
                                    <TableCell align="left">{detalle.symbol}</TableCell>
                                    <TableCell align="center">{detalle.quantity}</TableCell>
                                    <TableCell align="center">
                                      {modoEdicion === index ? (
                                        <TextField
                                          type="number"
                                          value={detalle.cantidadDevo || 0} // Manejo de undefined
                                          onChange={(e) => handleCantidadChange(index, e.target.value)}
                                          inputProps={{ min: 0 }}
                                        />
                                      ) : (
                                        detalle.cantidadDevo
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      {detalle.isSelected && modoEdicion === index ? (
                                        <IconButton onClick={() => handleSaveClick(index)}>
                                          <CheckIcon />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          onClick={() => {
                                            if (detalle.isSelected) handleEditClick(index); // Solo permitir editar si el checkbox está marcado
                                          }}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </div>
                    </div>
                  </div>
                </div>

                {/* AGREGAR DETALLES DEL RECLAMO */}
                <div className="col-md-12">
                  <div className="mb-3 row">
                    <label className="col-md-12 col-form-label"><b>Ingrese el detalle u observación:</b></label>
                    <div className="col-md-12">
                      <textarea
                        type="select"
                        className="form-control"
                        value={observaciones} // Enlaza con el estado `invoiceNumber`
                        onChange={(e) => setobservaciones(e.target.value)} // 
                      />
                    </div>
                  </div>
                </div>
                {/* AGREGAR IMAGENES DEL PRODUCTO */}
                <div className="col-md-12">
                  <div className="mb-3 row">
                    <label className="col-md-12 col-form-label"><b>Evidencias del reclamo:</b></label>
                    <div className="col-md-12">
                      <input
                        type="file"
                        className="form-control"
                        multiple
                        accept="image/jpeg, image/png, image/jpg"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                  {files.length > 0 && (
                    <div className="mt-3">
                      <h5>Archivos seleccionados:</h5>
                      <div className="row">
                        {files.map((file, index) => (
                          <div className="col-md-4" key={index}>
                            <div className="card mb-3">
                              <div className="card-body d-flex justify-content-between align-items-center">
                                <div className="me-2">
                                  <h6 className="card-title mb-0">{file.name}</h6>
                                  <small className="text-muted">Tamaño: {(file.size / 1024).toFixed(2)} KB</small>
                                  {file.type.startsWith('image/') && (
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                      className="img-fluid mt-2"
                                      style={{ maxHeight: '100px', objectFit: 'cover' }}
                                    />
                                  )}
                                </div>
                                <button
                                  type="button"
                                  onClick={() => handleRemoveFile(index)}
                                  className="btn btn-danger btn-sm"
                                  title="Eliminar archivo"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-trash-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center' }}>
                  <ReCAPTCHA
                    sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    // sitekey="6LdDx2gqAAAAALLBncY_l2ln4pxV8VV2pdXme9xG"
                    onChange={handleCaptcha}
                  />
                </div>
              </form>
              <div className="btn-toolbar mt-4" style={{ display: 'flex', justifyContent: 'center' }}>
                <button
                  type="submit"
                  className="btn btn-success"
                  style={{ width: '300px' }}
                  onClick={handleSubmit}>
                  Enviar
                </button>
              </div>
              {/* necesito que se confirme el proceso de envio del formulario */} 
            </div>
          </div>
        </div>
        {/* BOTONES DE CANCELAR Y GUARDAR */}
      </div>

    </>
  )
}
const closeButtonStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'transparent',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: 'red', // Cambia el color según tu preferencia
  padding: '0', // Asegura que no haya padding adicional
};

const modalStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000
}

const modalContentStyles = {
  backgroundColor: 'white',
  maxWidth: '600px', // Define el ancho máximo
  width: '90%',
  padding: '20px',
  borderRadius: '5px',
  textAlign: 'center'
}

const buttonStyles = {
  marginTop: '10px',
  padding: '10px 20px',
  border: 'none',
  color: 'white',
  borderRadius: '5px',
  cursor: 'pointer'
}
