import { TableCell, TableRow } from '@mui/material'
import React, { useState } from 'react'

export const RowCodigoValidacion = ({ detalle }) => {
    const [mostrarDetalle, setMostrarDetalle] = useState(false)
    const toggleDetalle = () => {
        setMostrarDetalle(!mostrarDetalle)
    }
    console.log('el detalle es ', detalle)
    return (
        <>
            <TableRow>
                <TableCell>{detalle.codigo}</TableCell>
                <TableCell>{detalle.nomUsu} {detalle.apeUsu}</TableCell>
                <TableCell align="center">
                    <span
                        className={
                            detalle.estadoCode === 1
                                ? 'badge text-bg-danger'
                                : detalle.estadoCode === 2
                                    ? 'badge text-bg-warning'
                                    : 'badge text-bg-success'
                        }
                    >
                        {detalle.estadoCode === 1
                            ? 'No Disponible' // Cambia "Usado" a "No Disponible"
                            : detalle.estadoCode === 0
                                ? 'Disponible' // Estado para el código 0
                                : 'Estado Desconocido' // Puedes agregar un estado por defecto si es necesario
                        }
                    </span>

                </TableCell>
                <TableCell>{detalle.fecCreCod}</TableCell>
                <TableCell>
                    <button
                        className="btn btn-link ms-auto" // Utiliza ms-auto para alinear a la derecha
                        onClick={toggleDetalle}
                    >

                    </button>
                </TableCell>
            </TableRow>
            {/* {mostrarDetalle && (
        <TableRow>
          <TableCell colSpan={6}>
            <RowOperacionesDevolucionCalidad
              detalleDevolucionesCalidad={detalle.detOpeDevCal}
            />
          </TableCell>
        </TableRow>
      )} */}
        </>
    )
}
