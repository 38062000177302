import axios from 'axios'
import config from '../../../config'

export const getProduccionLoteWithAgregacionesById = async (codLot) => {
  const domain = config.API_URL
  const path = '/produccion/produccion_lote_procesos/get_molienda_data.php'
  const url = domain + path

  try {
    // Hacer la petición con axios usando POST y enviando el codLot
    const { data } = await axios.post(url, {
      codLot: codLot
    })

    // Retorna los datos de la respuesta
    return data
  } catch (error) {
    // Manejar el error en caso de que falle la petición
    console.error('Error al obtener los datos de molienda:', error)
    throw error
  }
}
