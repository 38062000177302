import React, { useState, useRef } from 'react';
// IMPORTACIONES PARA EL FEEDBACK
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import logo from '../../../assets/logo-oficial.png';

// ESTILOS
import './../../styles/VerificationCodeInput.css';
import { useNavigate } from 'react-router-dom';

// VALIDACION DE CODIGO
import { validar_codigo } from '../../helpers/validacion/validacionCodigo';

// VALIDACION DEL LOCAL STORAGE
import { useLocalStorage } from '../../../hooks/useLocalStorage';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Login = () => {
    const navigate = useNavigate();

    // Estado para manejar el código de acceso
    const [code, setCode] = useState('');

    // Estado para manejar el local storage del código válido
    const [codigoValido, setCodigoValido] = useLocalStorage('codigoValido', false);
    const [codigo, setCodigo] = useLocalStorage('codigoCliente',"");

    // ESTADO PARA CONTROLAR EL FEEDBACK
    const [feedbackCreate, setFeedbackCreate] = useState(false);
    const [feedbackMessages, setFeedbackMessages] = useState({
        style_message: '',
        feedback_description_error: ''
    });
    const { style_message, feedback_description_error } = feedbackMessages;

    // MANEJADORES DE FEEDBACK
    const handleClickFeedback = () => {
        setFeedbackCreate(true);
    };

    const handleCloseFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFeedbackCreate(false);
    };

    // ESTADO PARA BOTON CREAR
    const [disableButton, setDisableButton] = useState(false);

    // SUBMIT DE LOGIN
    const submitLogin = async (e) => {
        e.preventDefault();
        if (code.length < 5) { 
            setFeedbackMessages({
                style_message: 'warning',
                feedback_description_error: 'Asegúrate de ingresar todos los caracteres del código de acceso.'
            });
            handleClickFeedback();
        } else {
            setDisableButton(true);
            const body = { code: code };
            const isValid = await validar_codigo(body);

            if (isValid.result !== null) {
                setFeedbackMessages({
                    style_message: 'success',
                    feedback_description_error: 'Código válido, bienvenido!'
                });
                handleClickFeedback();

                setCodigoValido(true);
                setCodigo(code);

                setTimeout(() => {
                    navigate('/cliente/operacion-devolucion');
                }, 1500);
            } else {
                setFeedbackMessages({
                    style_message: 'error',
                    feedback_description_error: 'Código de acceso incorrecto, por favor intenta de nuevo.'
                });
                handleClickFeedback();
            }
            setDisableButton(false);
        }
    };

    const inputRefs = useRef([]);

    const handleChange = (e, index) => {
        const value = e.target.value;

        if (value.length > 1) {
            return;
        }

        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode.join(''));

        if (value) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !e.target.value) {
            inputRefs.current[index - 1]?.focus();
        }
    };

    return (
        <>
            <div className="vh-100 d-flex justify-content-center align-items-center">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-md-8 col-lg-6">
                            <div className="card bg-white shadow-lg">
                                <div className="card-body p-5">
                                    <form className="mb-3 mt-md-4">
                                        <div className="text-center">
                                            <img
                                                src={logo}
                                                alt="Logo"
                                                width="180"
                                                height="170"
                                                className="d-inline-block align-text-top"
                                            />
                                        </div>
                                        <p className="mb-3">
                                            <b>Por favor ingresa el código de acceso!</b>
                                        </p>
                                        <div className="mb-3">
                                            <label htmlFor="codigo" className="form-label">
                                                Código de acceso:
                                            </label>
                                            <div className='d-flex justify-content-center'>
                                                {[...Array(5)].map((_, index) => (
                                                    <React.Fragment key={index}>
                                                        <input
                                                            type="text"
                                                            maxLength={1}
                                                            ref={el => inputRefs.current[index] = el}
                                                            onChange={(e) => handleChange(e, index)}
                                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                                            className="verification-input"
                                                            style={{ textTransform: 'uppercase' }}
                                                        />
                                                        {index < 4 && <span className="dash">-</span>}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="d-grid">
                                            <button
                                                onClick={(e) => submitLogin(e)}
                                                className="btn btn-outline-dark"
                                                type="submit"
                                                disabled={disableButton}
                                            >
                                                Validar
                                            </button>
                                        </div>
                                    </form>
                                    <Snackbar
                                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                        open={feedbackCreate}
                                        autoHideDuration={6000}
                                        onClose={handleCloseFeedback}
                                    >
                                        <Alert
                                            onClose={handleCloseFeedback}
                                            severity={style_message}
                                            sx={{ width: '100%' }}
                                        >
                                            {feedback_description_error}
                                        </Alert>
                                    </Snackbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
