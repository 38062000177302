import { HomeClientes } from '../pages/HomeCliente'
import { RouterAlmacenStockVentas } from '../pages/almacen/RouterAlmacenStockVentas'
import { RouterOperacionDevolucion } from '../pages/operacion-devolucion/RouterOperacionDevolucion'
import { Login } from '../pages/validacion/validacion'
import LayoutModulo from './../../layout/LayoutModulo'
import RutaProtegida from '../components/proteccion-route/RutaProtegida'

export const RouterCliente = [
  {
    path: '',
    element: <Login />
  },
  // {
  //   path: 'stock-almacen',
  //   element: <LayoutModulo />,
  //   children: RouterAlmacenStockVentas
  // },
  {
    path: 'operacion-devolucion',
    element: (<RutaProtegida>
      <LayoutModulo />
      </RutaProtegida>),
    children: RouterOperacionDevolucion
  }
]
