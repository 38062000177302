import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material'
import { CustomDialogUpdateOperation } from '../../../components/CustomComponents/CustomDialogUpdateOperation'
import { CustomDialogDeleteOperation } from '../../../components/CustomComponents/CustomDialogDeleteOperation'
import { DialogSalidaDetalleRequisicionIngresoProducto } from '../../../almacen/components/componentes-productos-lote/DialogSalidaDetalleRequisicionIngresoProducto'
// import { DialogSalidaDetalleRequisicionIngresoProducto } from './DialogSalidaDetalleRequisicionIngresoProducto'

export const CardRequisicionIngresoProductos = ({
  requisicion,
  onDeleteRequisicionAgregacionDetalle,
  onUpdateRequisicionAgregacionDetalle,
  onCheckRequisicionAgrgeacionDetalle
}) => {
  // console.log(requisicion)
  return (
    <div className="card mt-4">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h6>Presentación final del lote</h6>
      </div>
      <div className="card-body">
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: '#F3DBB6' }}>
              <TableRow>
                <TableCell>
                  <b>Ref.</b>
                </TableCell>
                <TableCell>
                  <b>Presentación</b>
                </TableCell>
                <TableCell>
                  <b>Cantidad Stock</b>
                </TableCell>
                <TableCell>
                  <b>Fecha entrada</b>
                </TableCell>
                <TableCell>
                  <b>Estado</b>
                </TableCell>
                <TableCell>
                  <b>Acciones</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{requisicion.refProdtProg}</TableCell>
                <TableCell>{requisicion.nomProd}</TableCell>
                {/* <TableCell>{requisicion.canProdIng}</TableCell> */}
                <TableCell>{requisicion.canTotDis}</TableCell>
                <TableCell>{requisicion.fecProdIng}</TableCell>
                <TableCell>
                  <span
                    className={
                      requisicion.esComProdIng === 0
                        ? 'badge text-bg-danger'
                        : 'badge text-bg-success'
                    }
                  >
                    {requisicion.esComProdIng === 0 ? 'Requerido' : 'Completo'}
                  </span>
                </TableCell>
                <TableCell>
                  <button
                    className='btn btn-danger me-2'
                    onClick={() => onDeleteRequisicionAgregacionDetalle(requisicion.id)} // Cambia aquí
                    detalle={requisicion}
                    formato={{
                      nombre: 'nomProd',
                      cantidad: 'canIng',
                      medida: 'simMed'
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                    </svg>
                  </button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}
