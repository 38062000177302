import React, { useEffect, useState } from 'react'
import { FormatDateMYSQL } from '../../../utils/functions/FormatDate'
// import { listOperacionesDevolucion } from '../../../helpers/operacion-devolucion/listOperacionesDevolucion'
import { listSolicitudDevolucion } from '../../helpers/solicitud-devolucion/listSolicitudDevolucion'
import { listCodigosValidacion } from '../../helpers/solicitud-devolucion/listCodigosValidacion'
import FechaPickerMonth from '../../../components/Fechas/FechaPickerMonth'
import { RowOperacionSolicitudDevolucion } from '../../components/operacion-solicitud-devolucion/RowOperacionSolicitudDevolucion'
import { RowCodigoValidacion } from '../../components/operacion-solicitud-devolucion/RowCodigoValidacion'
import { generateCodigoValidacion } from '../../helpers/solicitud-devolucion/generateCodigoValidacion'
import { FilterClientesEmafact } from '../../../components/ReferencialesFilters/Clientes/FilterClientesEmafact'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material'
// import { RowOperacionDevolucionNoRetorno } from '../../components/operacion-devolucion/RowOperacionDevolucionNoRetorno'
// Para poder tener el ip del usuarios
import { useAuth } from '../../../hooks/useAuth'
export const ListCodigosOperacionDevolucionVentas = () => {
    // ESTADOS PARA LOS FILTROS PERSONALIZADOS
    const [dataOperacionDevolucion, setdataOperacionDevolucion] = useState([])
    const [openModal, setOpenModal] = useState(false);


    // ESTADOS PARA FILTROS GENERALES DE FECHA
    // filtros
    const [formState, setformState] = useState({
        fechaInicio: FormatDateMYSQL(),
        fechaFin: FormatDateMYSQL()
    })

    const [Cliente, setCliente] = useState({
        idCliente: "",
        value: ""
    })

    // Filtros generales que hacen nuevas consultas
    const handleFechaInicioChange = (newfecEntSto) => {
        const dateFormat = newfecEntSto.split(' ')[0]
        setformState({
            ...formState,
            fechaInicio: dateFormat
        })

        // armamos el body
        const body = {
            ...formState,
            fechaInicio: dateFormat
        }
        obtenerDataOperacionDevolucion(body)
    }

    const handleFechaFinChange = (newfecEntSto) => {
        const dateFormat = newfecEntSto.split(' ')[0]
        setformState({
            ...formState,
            fechaFin: dateFormat
        })

        // armamos el body
        const body = {
            ...formState,
            fechaFin: dateFormat
        }
        obtenerDataOperacionDevolucion(body)
    }

    // Esta función se llamará cuando selecciones un cliente en FilterClientesEmafact

    const handleClienteSelect = (cliente) => {
        const { id, label } = cliente // Supongamos que el cliente tiene un campo 'id'
        console.log('esta entrando aca')
        // Actualiza el estado del cliente seleccionado
        setCliente({
            idCliente: id, // Almacena el id del cliente seleccionado
            value: label,
        })
        console.log(cliente)
    }

    const obtenerDataOperacionDevolucion = async (body = null) => {
        const resultPeticion = await listCodigosValidacion(body)
        const { result, message_error, description_error } = resultPeticion
        // console.log(resultPeticion)
        if (message_error.length === 0) {
            setdataOperacionDevolucion(result)
        } else {
            alert(description_error)
        }

    }

    useEffect(() => {
        obtenerDataOperacionDevolucion()
    }, [])
    const { logout } = useAuth();
    const [show, setShow] = useState(false)
    const [userId, setUserId] = useState(null) // Estado para almacenar el ID del usuario

    // Esta función se ejecuta al cargar el componente
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user) {
            const { idRolUsu, idUsu } = user; // Extrae el ID del usuario también
            // si es un usuario administrador
            if (idRolUsu === 1) {
                setShow(true)
            }
            // Guarda el ID del usuario en el estado
            setUserId(idUsu)
        } else {
            console.log('No hay usuario en localStorage')
        }
    }, []);
    // const logoutUser = () => {
    //     // cerramos sesión
    //     logout()
    // };

    // const verifyAdminUser = () => {
    //     const user = JSON.parse(localStorage.getItem('user'))
    //     if (user) {
    //         const { idRolUsu, id } = user; // Extrae el ID del usuario también
    //         // si es un usuario administrador
    //         if (idRolUsu === 1) {
    //             setShow(true)
    //         }
    //         // Guarda el ID del usuario en el estado
    //         setUserId(id)
    //     } else {
    //         console.log('No entro porque nose ')
    //     }
    // };

    // useEffect(() => {
    //     verifyAdminUser();
    // }, [])

    const handleCreate = async () => {
        // Ahora puedes utilizar userId directamente
        if (userId) {
            try {
                const body = {
                    idUsu: userId,
                    idCliente: Cliente.idCliente,
                    decCliente: Cliente.value
                };
                // Esperar el resultado de la función generateCodigoValidacion
                const resultPeticion = await generateCodigoValidacion(body);
                handleCloseModal();
                // Pasar el resultado a compGenerarCodigoValidacion
                await compGenerarCodigoValidacion(resultPeticion);
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            console.log('No entro porque el usuario no existe o el ID no está disponible');
        }
    };
    const compGenerarCodigoValidacion = async (resultPeticion) => {
        const { result, message_error, description_error } = resultPeticion;

        // console.log(resultPeticion);
        if (message_error.length === 0) {
            obtenerDataOperacionDevolucion();
        } else {
            alert(description_error);
        }
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handleOpenModal = () => {
        setOpenModal(true);

    };
    return (
        <>
            <div className="container-fluid">
                <div className="row d-flex mt-4 mb-4">
                    <div className="col-12">
                        <div className="row" style={{ border: '0px solid black' }}>
                            <div
                                className="col-2"
                                style={{
                                    border: '0px solid black',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <FechaPickerMonth
                                    onNewfecEntSto={handleFechaInicioChange}
                                    label="Desde"
                                />
                            </div>
                            <div
                                className="col-2"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <FechaPickerMonth
                                    onNewfecEntSto={handleFechaFinChange}
                                    label="Hasta"
                                />
                            </div>
                            <div
                                className="col-6 d-flex justify-content-end align-items-center"
                                onClick={handleOpenModal}
                            >
                                <div className="row">
                                    <div className="col-12 btn btn-primary d-inline-flex justify-content-end align-items-center" style={{ marginRight: 'pointer' }}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-plus-circle-fill me-2"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                        </svg>
                                        Crear
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal de búsqueda de clientes */}
                <Dialog fullWidth open={openModal} onClose={handleCloseModal}>
                    <DialogTitle>Seleccione Cliente</DialogTitle>
                    <DialogContent>
                        <FilterClientesEmafact
                        style={{ Width: '40%' }}
                        onNewInput={handleClienteSelect}>
                        </FilterClientesEmafact>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal}>Cancelar</Button>
                        <Button
                            onClick={handleCreate}
                            color="primary"
                        // disabled={!selectedCliente}
                        >
                            Aceptar
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* TABLA DE CONTENIDO */}
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow
                                sx={{
                                    '& th': {
                                        color: 'rgba(96, 96, 96)',
                                        backgroundColor: '#f5f5f5'
                                    }
                                }}
                            >
                                <TableCell align="left" width={20}>
                                    <b>Código</b>
                                </TableCell>
                                <TableCell align="left" width={80}>
                                    <b>Usuario Creador</b>
                                </TableCell>
                                <TableCell align="left" width={220}>
                                    <b>Cliente</b>
                                </TableCell>
                                <TableCell align="center" width={30}>
                                    <b>Estado</b>
                                </TableCell>
                                <TableCell align="center" width={40}>
                                    <b>Fecha Creación</b>
                                </TableCell>
                                <TableCell align="left" width={50}>
                                    <b>Acciones</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataOperacionDevolucion.map((element) => (
                                <RowCodigoValidacion
                                    key={element.id}
                                    detalle={element}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
}
