import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { stylesPDF } from './stylePDF';
import { _parseInt } from '../../../utils/functions/ParseInt';
const styles = stylesPDF;

export const DetalleOrden = ({ result }) => {
  // importamos los estilos
  return (
    <View style={{ ...styles.section, marginTop: -25 }}>
      <Text
        style={{
          ...styles.title,
          fontWeight: 'bold',
          fontSize: 10,
          marginLeft: -428,
          marginTop: 10
        }}
      >
        Datos de Calidad
      </Text>
      
      {/* Cabeceras con valores de labGruAtr, usando 'DATOS GENERALES' si labGruAtr es null o vacío */}
      {[...new Set(result.informacion_atributos?.map((atributo) => atributo.labGruAtr))]
        .map((labGruAtr, index) => {
          const displayLabGruAtr = labGruAtr || "DATOS GENERALES"; // Valor predeterminado

          return (
            <React.Fragment key={index}>

              {/* Encabezado de datos de calidad labGruAtr */}
              <View style={[styles.gridHeader, styles.greenBackground]}>
                <Text style={{ ...styles.gridContent_p, flex: 0.7 }}>
                  {displayLabGruAtr}
                </Text>
              </View>

              {/* Filtrar atributos por labGruAtr y mapear sus valores */}
              {result.informacion_atributos
                ?.filter((atributo) => atributo.labGruAtr === labGruAtr)
                .map((atributoFiltrado, indexFiltrado) => (
                  <React.Fragment key={indexFiltrado}>
                    {result.informacion_valores_atributos
                      ?.filter((valores) => valores.idProdtAtrCal === atributoFiltrado.id)
                      .map((valores, indexValores) => (
                        <View
                          key={indexValores}
                          style={[
                            styles.gridRow,
                            indexValores % 2 === 0 ? { backgroundColor: '#f0f0f0' } : {} // Color de fondo para filas alternas
                          ]}
                        >
                          <Text style={{
                            ...styles.gridContent_p,
                            flex: 2,
                            backgroundColor: '#d8dade', // Fondo más oscuro
                            padding: 5 // Ajusta el padding según sea necesario
                          }}>
                            {atributoFiltrado.nomProdAtr}
                          </Text>
                          <Text style={{
                            ...styles.gridContent_p,
                            flex: 4,
                            textAlign: 'left',
                            backgroundColor: '#f9f9f9', // Fondo más claro
                            padding: 5 // Ajusta el padding según sea necesario
                          }}>
                            {valores.valEntCalAtr}
                          </Text>
                        </View>
                      ))}
                  </React.Fragment>
                ))}

              {/* Espacio adicional después de todos los detalles del producto */}
              <View style={{ height: 20 }} /> {/* Espacio de 20 unidades */}
            </React.Fragment>
          );
        })}
    </View>
  );
};
