import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// IMPORTACIONES PARA TABLE MUI
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
// IMPORTACIONES PARA EL FEEDBACK
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import FechaPicker from '../../../components/Fechas/FechaPicker'
import { TextField, Typography, Checkbox, FormControlLabel } from '@mui/material'
import FechaPickerYear from './../../../components/Fechas/FechaPickerYear'
import { getFormulaProductoDetalleByProducto } from '../../helpers/formula_producto/getFormulaProductoDetalleByProducto'
import { getFormulaWithDetalleByProducto } from '../../helpers/formula/getFormulaWithDetalleByProducto'
import { RowEditDetalleProductosFinales } from './../../components/componentes-lote-produccion/RowEditDetalleProductosFinales'
import { RowEditDetalleRequisicionProduccion } from '../../components/componentes-lote-produccion/RowEditDetalleRequisicionProduccion'
import { RowEditDetalleRequisicionProduccionSeleccion } from '../../components/componentes-lote-produccion-procesos/RowEditDetalleRequisicionProduccionSeleccion'
import { createProduccionLoteWithRequisiciones2 } from '../../helpers/produccion-lote-procesos/createProduccionLoteWithRequisiciones2'
import { getMateriaPrimaById } from './../../../helpers/Referenciales/producto/getMateriaPrimaById'
import { FilterAreaEncargada } from './../../components/FilterAreaEncargada'
import {
  FormatDateTimeMYSQLNow,
  FormatDateTimeMYSQLNowPlusYears
} from '../../../utils/functions/FormatDate'

// IMPROTACIONES PARA LINEA DE PROGRESION
import { getRequisicionProcesoProduccion } from '../../helpers/produccion_lote/getRequisicionProcesoProduccion'
import { FilterPresentacionFinalDynamic } from '../../../components/ReferencialesFilters/Producto/FilterPresentacionFinalDynamic'
import { FilterProductoProduccionDynamic } from '../../../components/ReferencialesFilters/Producto/FilterProductoProduccionDynamic'
import { FilterAllProductosDynamic } from '../../../components/ReferencialesFilters/Producto/FilterAllProductosDynamic'
import { FilterTipoProduccionDynamic } from '../../../components/ReferencialesFilters/TipoProduccion/FilterTipoProduccionDynamic'
// import { createProduccionLoteWithRequisiciones2 } from '../../helpers/produccion_lote/createProduccionLoteWithRequisiciones2'
// CREAR REQUISICION MOLIENDA
import { FilterProductoProduccion } from './../../../components/ReferencialesFilters/Producto/FilterProductoProduccion'
// CONFIGURACION DE FEEDBACK
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

function onValidate(e) {
  const t = e.value
  e.value = t.indexOf('.') >= 0 ? t.slice(0, t.indexOf('.') + 3) : t
  return e.value
}

// se encarga de redondear los valores de las requisiciones
function _parseInt(str) {
  if (str.canReqProdLot) {
    str.canReqDet = str.canReqProdLot
  }

  if (str.canTotProgProdFin) {
    str.canReqDet = str.canTotProgProdFin
  }
  str.canReqDet = parseFloat(str.canReqDet).toFixed(2)
  const index = str.canReqDet.toString().indexOf('.')
  const result = str.canReqDet.toString().substring(index + 1)
  // console.log("index: ",index, "result: ", result)
  const val =
    parseInt(result) >= 1 && str.simMed !== 'KGM'
      ? Math.trunc(str.canReqDet) + 1
      : str.canReqDet
  return val
}

export const CrearProduccionProcesos = () => {
  // ESTADO PARA LINEA DE PROGRESO
  const [_klgLotProd, setKlgLotProd] = useState(0)

  // ESTADO PARA CONTROLAR EL FEEDBACK
  const [feedbackCreate, setfeedbackCreate] = useState(false)
  const [feedbackMessages, setfeedbackMessages] = useState({
    style_message: '',
    feedback_description_error: ''
  })
  const { style_message, feedback_description_error } = feedbackMessages

  // MANEJADORES DE FEEDBACK
  const handleClickFeeback = () => {
    setfeedbackCreate(true)
  }

  const handleCloseFeedback = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setfeedbackCreate(false)
  }

  // ESTADO PARA LOS DATOS DE PRODUCCION LOTE
  const [produccionLote, setproduccionLote] = useState({
    idProdt: 0, // producto intermedio (viene del backend)
    idSubCla: 0, // sub clase
    idReq: 0,
    idProdTip: 7, // envasado y encajado
    codTipProd: 'EE',
    codLotProd: '', // codigo de lote (viene del backend)
    klgLotProd: 1, // kilogramos del lote (viene del backend)
    canLotProd: 1, // cantidad (viene del backend)
    klgTotalLoteProduccion: 0,
    klgDisponibleLoteProduccion: 0,
    totalUnidadesLoteProduccion: 0, // cantidad
    obsProd: '', // observaciones
    fecProdFinProg: '', // fecha de fin programado
    fecVenLotProd: '', // fecha de vencimiento del lote
    reqDetProdc: [], // detalle requisicion de lote
    prodDetProdc: [], // detalle de productos finales esperados
    fecProdIniProg: FormatDateTimeMYSQLNow()
  })

  const {
    idProdt,
    idSubCla,
    idProdTip,
    codLotProd,
    klgLotProd,
    canLotProd,
    obsProd,
    fecProdIniProg,
    fecProdFinProg,
    fecVenLotProd,
    reqDetProdc,
    prodDetProdc
  } = produccionLote

  // ESTADO DE KLG DISPONIBLES PARA LOTE PRODUCCION
  const [cantidadLoteProduccion, setcantidadLoteProduccion] = useState({
    totalUnidadesLoteProduccion: 0,
    klgTotalLoteProduccion: 0,
    klgDisponibleLoteProduccion: 0
  })

  const {
    totalUnidadesLoteProduccion,
    klgTotalLoteProduccion
  } = cantidadLoteProduccion

  // STATE PARA CONTROLAR LA AGREGACION DE PRODUCTOS FINALES DEL LOTE
  const [productoLoteProduccion, setproductoLoteProduccion] = useState({
    idProdFin: 0,
    cantidadDeLote: 0.0,
    cantidadDeProducto: 0
  })

  // producto final informacion
  const [formulaProductoFinal, setFormulaProductoFinal] = useState(null)

  // STATE PARA CONTROLAR LOS PRODUCTOS ADITIVOS A LAS REQUISICIONES DEL LOTE
  const [productoRequisicionProduccion, setproductoRequisicionProduccion] =
    useState({
      idProdReq: 0,
      cantidadRequisicion: 0,
      idAre: 0
    })

  const { idProdReq, cantidadRequisicion, idAre } =
    productoRequisicionProduccion

  // ESTADO PARA BOTON CREAR

  // ESTADOS PARA LA NAVEGACION
  const navigate = useNavigate()
  const onNavigateBack = () => {
    navigate(-1)
  }

  // ******** DATOS DEL LOTE DE PRODUCCION ********
  // CONTROLADOR DE FORMULARIO
  const handledForm = ({ target }) => {
    const { name, value } = target
    setproduccionLote({
      ...produccionLote,
      [name]: value
    })
  }

  // Evento para traer requisicion correspondiente al producto intermedio
  const onAddProductoProduccion = async ({ id }) => {
    const response = await getRequisicionProcesoProduccion({ idProdt: id })
    // const response2 = await getFormulaWithDetalleByProducto({ idProdt: id })
    // console.log ('response', response)
    const { result } = response
    // onAddProductoFinalLoteProduccion2({ id })
    console.log({ id })
    if (result.length === 0) {
      // Mostramos una alerta
      setfeedbackMessages({
        style_message: 'error',
        feedback_description_error:
          'No hay requisiciones disponibles para este proceso de produccion'
      })
      handleClickFeeback()
    } else {
      // si hay 2 requisiciones disponibles para este lote entonces mostramos una alerta
      // if (result.length > 1) {
      //   // Mostramos una alerta
      //   setfeedbackMessages({
      //     style_message: 'warning',
      //     feedback_description_error:
      //       'Hay mas de una requisicion disponible para este proceso de produccion\n' +
      //       'Por defecto tomaremos la más reciente, pero asegurate de verificar si es correcto'
      //   })
      //   handleClickFeeback()
      // } else {
      //   // Mostramos una alerta
      //   setfeedbackMessages({
      //     style_message: 'success',
      //     feedback_description_error:
      //       'Se jalo correctamente de la requisición\n'
      //   })
      //   handleClickFeeback()
      // }
      const canLotProd = parseFloat(result[0].cantProg) // jalamos de la cantidad programada
      setproduccionLote({
        ...produccionLote,
        idProdt: id,
        idReq: result[0].id,
        canLotProd,
        klgLotProd: canLotProd,
        codLotProd: result[0].codLotProd,
        idSubCla: result[0].idSubCla,
        fecVenLotProd: FormatDateTimeMYSQLNowPlusYears(
          result[0].idSubCla === 50 ? 1 : 4
        )
      })
      setKlgLotProd(canLotProd)
    }
  }

  // EVENTO DE TIPO DE PRODUCCION
  const onAddTipoProduccion = ({ id, cod }) => {
    setproduccionLote({
      ...produccionLote,
      idProdTip: id,
      codTipProd: cod
    })
  }

  // ENVENTO DE FECHA INICIO PROGRAMADO
  const onAddFechaInicioProgramado = (newFecha) => {
    let year = 0
    // si la UM de al presentacion final es LTS, entonces year = 1
    if (idSubCla === 50) {
      year = 1 // frescos
    } else {
      year = 4 // otros
    }

    const newfecVenProd = FormatDateTimeMYSQLNowPlusYears(year, newFecha)

    setproduccionLote({
      ...produccionLote,
      fecProdIniProg: newFecha,
      fecVenLotProd: newfecVenProd
    })
  }

  // EVENTO DE FECHA FIN PROGRAMADO
  const onAddFechaFinProgramado = (newFecha) => {
    setproduccionLote({ ...produccionLote, fecProdFinProg: newFecha })
  }

  // EVENTO DE FECHA VENCIMIENTO LOTE
  const onAddFechaVencimientoLoteProduccion = (newFecha) => {
    setproduccionLote({ ...produccionLote, fecVenLotProd: newFecha })
  }

  // ******** EVENTOS DE FILTRO PARA FORMULA DE MOLIENDA ********
  const onAddProductoFinalLoteProduccion2 = async ({ id, value }) => {
    try {
      const { result } = await getFormulaWithDetalleByProducto({ idProdt: id });
      // console.log('El resultado de la consulta a fórmula es:', result);
      if (result.length === 1) {
        const { reqDet } = result[0] // obtenemos las requisiciones
        console.log('reqDet', reqDet)
        let reqProdInt = null
        const reqEnvEnc = []

        reqDet.forEach((detalle) => {
          // if (detalle.idAre === 2 || detalle.idAre === 7) {
          reqProdInt = detalle
          // } else {
          //   reqEnvEnc.push(detalle)
          // }
          // console.log('detalle', detalle)
        })

        if (reqProdInt !== null) {
          const formulaPresentacionFinal = {
            id: reqDet[0].id,
            idProdFin: reqDet[0].idMatPri,
            nomProd: reqDet[0].nomProd,
            simMed: reqDet[0].simMed,
            canForProInt: reqProdInt.canMatPriFor,
            reqDet: reqEnvEnc
          }

          setFormulaProductoFinal(formulaPresentacionFinal)

          setproductoLoteProduccion({
            idProdFin: id,
            cantidadDeLote: 0.0,
            cantidadDeProducto: 0
          })
        } else {
          setfeedbackMessages({
            style_message: 'warning',
            feedback_description_error:
              'Esta formula no tiene información de su producto intermedio'
          })
          handleClickFeeback()

          // reseteamos los campos
          setproductoLoteProduccion({
            idProdFin: 0,
            cantidadDeLote: 0.0,
            cantidadDeProducto: 0
          })
        }
      } else {
        setfeedbackMessages({
          style_message: 'warning',
          feedback_description_error:
            'No hay formulas o hay mas de una formula para esta presetacion final'
        })
        handleClickFeeback()

        // reseteamos los campos
        setproductoLoteProduccion({
          idProdFin: 0,
          cantidadDeLote: 0.0,
          cantidadDeProducto: 0
        })
      }
      handleAddProductoRequisicionLote()
    } catch (error) {
      console.error('Error en onAddProductoFinalLoteProduccion2:', error);
    }
  }
  // ******** EVENTOS DEL FILTRO DE PRODUCTO *********

  const onAddProductoFinalLoteProduccion = async ({ id, value }) => {
    const { result } = await getFormulaProductoDetalleByProducto(id)
    if (result.length === 1) {
      const { reqDet } = result[0] // obtenemos las requisiciones
      let reqProdInt = null
      const reqEnvEnc = []

      reqDet.forEach((detalle) => {
        if (detalle.idAre === 2 || detalle.idAre === 7) {
          reqProdInt = detalle
        } else {
          reqEnvEnc.push(detalle)
        }
      })

      if (reqProdInt !== null) {
        const formulaPresentacionFinal = {
          id: result[0].id,
          idProdFin: result[0].idProdFin,
          nomProd: result[0].nomProd,
          simMed: result[0].simMed,
          canForProInt: reqProdInt.canForProDet,
          reqDet: reqEnvEnc
        }

        setFormulaProductoFinal(formulaPresentacionFinal)

        setproductoLoteProduccion({
          idProdFin: id,
          cantidadDeLote: 0.0,
          cantidadDeProducto: 0
        })
      } else {
        setfeedbackMessages({
          style_message: 'warning',
          feedback_description_error:
            'Esta formula no tiene información de su producto intermedio'
        })
        handleClickFeeback()

        // reseteamos los campos
        setproductoLoteProduccion({
          idProdFin: 0,
          cantidadDeLote: 0.0,
          cantidadDeProducto: 0
        })
      }
    } else {
      setfeedbackMessages({
        style_message: 'warning',
        feedback_description_error:
          'No hay formulas o hay mas de una formula para esta presetacion final'
      })
      handleClickFeeback()

      // reseteamos los campos
      setproductoLoteProduccion({
        idProdFin: 0,
        cantidadDeLote: 0.0,
        cantidadDeProducto: 0
      })
    }
  }

  // funcion para manejar la cantidad de klg requerida
  const handleInputsProductoFinalLote = ({ target }) => {
    const { value } = target
    // cantidad requerida de klg de lote para presentacion final
    try {
      const cantidadKlgRequerida = value

      if (formulaProductoFinal !== null) {
        // cantidad de klg de producto intermedio por unidad de presentacion final
        const canKlgProdIntByUni = formulaProductoFinal.canForProInt
        // cantidad de unidades obtenidas segun klg requerido ingresado
        const cantidadUniRequerida = parseInt(
          parseFloat(cantidadKlgRequerida) / parseFloat(canKlgProdIntByUni)
        )

        setproductoLoteProduccion({
          ...productoLoteProduccion,
          cantidadDeLote: cantidadKlgRequerida,
          cantidadDeProducto: cantidadUniRequerida
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  // funcion para manejar la cantidad de unidades requeridas
  const handleInputsProductoFinalCantidad = ({ target }) => {
    const { value } = target
    // cantidad requerida de klg de lote para presentacion final
    try {
      const cantidadUniRequerida = value
      let cantidadKlgRequerida = 0

      if (formulaProductoFinal !== null) {
        // cantidad de klg de producto intermedio por unidad de presentacion final
        const canKlgProdIntByUni = formulaProductoFinal.canForProInt
        // cantidad de unidades obtenidas segun klg requerido ingresado
        cantidadKlgRequerida =
          parseInt(cantidadUniRequerida) * parseFloat(canKlgProdIntByUni)
        cantidadKlgRequerida = cantidadKlgRequerida.toFixed(5)
        setproductoLoteProduccion({
          ...productoLoteProduccion,
          cantidadDeLote: cantidadKlgRequerida,
          cantidadDeProducto: cantidadUniRequerida
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onAddProductoRequisicionLoteProduccion = (value) => {
    setproductoRequisicionProduccion({
      ...productoRequisicionProduccion,
      idProdReq: value.id
    })
  }
  // MANEJADOR DE INPUTS REQUISICION
  const handleInputsProductosRequisicion = ({ target }) => {
    const { value, name } = target
    setproductoRequisicionProduccion({
      ...productoRequisicionProduccion,
      [name]: value
    })
  }

  // MAJEADOR PARA AGREGAR EL AREA AL FILTRO
  const handleAreaIdProductoRequisicion = ({ id }) => {
    setproductoRequisicionProduccion({
      ...productoRequisicionProduccion,
      idAre: id
    })
  }

  // añadir un detalle
  const handleAddProductoRequisicionLote = async (e) => {
    e.preventDefault()

    if (idProdReq !== 0 && idAre !== 0 && cantidadRequisicion > 0.0) {
      const itemFound = reqDetProdc.find(
        (element) => element.idProd === idProdReq
      )
      if (itemFound) {
        setfeedbackMessages({
          // style_message: 'warning',
          // feedback_description_error:
          //   'Ya se agrego este producto a la requisicion'
        })
        handleClickFeeback()
      } else {
        // solo permitir agregaciones de area embalaje y encajonado
        if (idAre === 5 || idAre === 6) {
          const resultPeticion = await getMateriaPrimaById(idProdReq)
          const { message_error, description_error, result } = resultPeticion

          if (message_error.length === 0) {
            const { id, codProd, desCla, desSubCla, nomProd, simMed } =
              result[0]
            // generamos nuestro detalle de formula
            const detalleFormulaProducto = {
              idProd: id,
              idAre, // area
              idAlm: 1, // almacen de orgien
              nomAlm: 'Almacen Principal',
              codProd,
              desCla,
              desSubCla,
              nomProd,
              simMed,
              canForProDet: 1,
              canReqProdLot: cantidadRequisicion // cantidad
            }

            // seteamos el detalle en general de la formula
            const dataDetalle = [...reqDetProdc, detalleFormulaProducto]

            setproduccionLote({
              ...produccionLote,
              reqDetProdc: dataDetalle
            })
          } else {
            setfeedbackMessages({
              style_message: 'error',
              feedback_description_error: description_error
            })
            handleClickFeeback()
          }
        } else {
          setfeedbackMessages({
            style_message: 'warning',
            feedback_description_error:
              'Solo se adminte areas de envasado y encajonado'
          })
          handleClickFeeback()
        }
      }
    } else {
      let advertenciaDetalleRequisicion = ''
      if (idProdReq === 0) {
        advertenciaDetalleRequisicion +=
          'Debe elegir un envase, embalaje u otro material para agregar el detalle\n'
      }
      if (idAre === 0) {
        advertenciaDetalleRequisicion +=
          'Debe asignar un area para agregar el detalle\n'
      }
      if (cantidadRequisicion <= 0) {
        advertenciaDetalleRequisicion +=
          'Debe proporcionar una cantidad mayor a 0 para agregar el detalle\n'
      }

      setfeedbackMessages({
        style_message: 'warning',
        feedback_description_error: advertenciaDetalleRequisicion
      })
      handleClickFeeback()
    }
  }

  const handleDeleteItemRequisicionProduccion = (idItem, index) => {
    const dataDetalleRequisicionProduccion = reqDetProdc.filter((element) => {
      if (element.idProd === idItem && element.indexProdFin === index) {
        return false
      } else {
        return true
      }
    })

    setproduccionLote({
      ...produccionLote,
      reqDetProdc: dataDetalleRequisicionProduccion
    })
  }

  const handleEditItemRequisicionProduccion = ({ target }, idItem, index) => {
    const { value } = target
    // console.log("test ", value);
    const editFormDetalle = reqDetProdc.map((element) => {
      if (element.idProd === idItem && element.indexProdFin === index) {
        return {
          ...element,
          canReqProdLot: value
        }
      } else {
        return element
      }
    })
    setproduccionLote({
      ...produccionLote,
      reqDetProdc: editFormDetalle
    })
  }

  const handleAddProductoProduccionLote = async (e) => {
    e.preventDefault()
    // equivalente en klg
    const cantidadDeLote = productoLoteProduccion.cantidadDeLote
    // equivalente en unidades
    const cantidadDeProducto = productoLoteProduccion.cantidadDeProducto

    // primero verificamos si se ha ingresado la data necesaria
    if (
      productoLoteProduccion.idProdFin !== 0 &&
      (cantidadDeLote > 0.0 || cantidadDeProducto > 0)
    ) {
      const itemFound = prodDetProdc.find(
        (element) => element.idProdFin === productoLoteProduccion.idProdFin
      )

      // verificamos si este producto final ha sido agregado previamente
      if (itemFound) {
        setfeedbackMessages({
          style_message: 'warning',
          feedback_description_error: 'Ya se agrego este producto a la orden'
        })
        handleClickFeeback()
      } else {
        if (formulaProductoFinal !== null) {
          const { nomProd, simMed, reqDet, canForProInt } =
            formulaProductoFinal

          console.log(canForProInt, reqDet)

          // primero verificamos si la cantidad nueva ingresada supera nuestro limite de peso de lote
          // total de kilogramos de orden de produccion hasta el momento
          const klgTotalOrdenProduccion = parseFloat(klgTotalLoteProduccion)
          // total de kilogramos de la presentacion final
          const klgTotalPresentacionFinal = parseFloat(cantidadDeLote)

          // cantidad total luego de agregar el total de kilogramos de la presentacion final
          const cantTotKlgAgr =
            klgTotalOrdenProduccion + klgTotalPresentacionFinal

          // peso total de la orden de produccion
          const pesoOrdenProduccion = parseFloat(klgLotProd)

          // si la cantidad total agregada recientemente no supera el total del peso de la orden
          if (cantTotKlgAgr <= pesoOrdenProduccion) {
            // calculamos el total de unidades
            const canTotUndAgr =
              parseInt(totalUnidadesLoteProduccion) +
              parseInt(cantidadDeProducto)

            // actualizamos las cantidades actuales
            setcantidadLoteProduccion({
              ...cantidadLoteProduccion,
              klgTotalLoteProduccion: cantTotKlgAgr,
              totalUnidadesLoteProduccion: canTotUndAgr
            })

            // actualizamos requisicion de productos finales
            const nextIndex = prodDetProdc.length + 1
            const detalleProductosFinales = [
              ...prodDetProdc,
              {
                idProdFin: productoLoteProduccion.idProdFin,
                index: nextIndex,
                nomProd,
                simMed,
                canUnd: cantidadDeProducto,
                canKlg: cantidadDeLote
              }
            ]

            const detalleRequisicionesFormula = []

            reqDet.forEach((detalle) => {
              detalleRequisicionesFormula.push({
                ...detalle,
                indexProdFin: nextIndex,
                idProdFin: productoLoteProduccion.idProdFin,
                canReqProdLot: parseFloat(
                  cantidadDeProducto * detalle.canForProDet
                ).toFixed(5)
              })
            })
            console.log(detalleRequisicionesFormula)

            detalleRequisicionesFormula.forEach((obj) => {
              obj.canReqProdLot = _parseInt(obj)
            })

            const detalleRequisicion = [
              ...reqDetProdc,
              ...detalleRequisicionesFormula
            ]

            // lo insertamos en el detalle
            setproduccionLote({
              ...produccionLote,
              prodDetProdc: detalleProductosFinales,
              reqDetProdc: detalleRequisicion
            })

            // reseteamos los campos
            setproductoLoteProduccion({
              idProdFin: 0,
              cantidadDeLote: 0.0,
              cantidadDeProducto: 0
            })

            setFormulaProductoFinal(null)
          } else {
            setfeedbackMessages({
              style_message: 'warning',
              feedback_description_error:
                'Asegurese de que la asignancion de kg de lote sea menor al peso total permitido'
            })
            handleClickFeeback()
          }
        } else {
          setfeedbackMessages({
            style_message: 'warning',
            feedback_description_error:
              'No se ha seleccionado ninguna presentacion final'
          })
          handleClickFeeback()
        }
      }
    } else {
      let advertenciaPresentacionFinal = ''
      if (productoLoteProduccion.idProdFin === 0) {
        advertenciaPresentacionFinal +=
          'Se debe proporcionar una presentacion final para agregar a la orden\n'
      }
      if (
        productoLoteProduccion.cantidadDeLote <= 0.0 ||
        productoLoteProduccion.cantidadDeProducto <= 0
      ) {
        advertenciaPresentacionFinal +=
          'Se debe proporcionar una cantidad mayor a 0 para agregar a la orden\n'
      }

      // mostramos el mensaje de error
      setfeedbackMessages({
        style_message: 'warning',
        feedback_description_error: advertenciaPresentacionFinal
      })
      handleClickFeeback()
    }
  }

  // ELIMINAR UN PRODUCTO FINAL Y SU REQUISICION
  const handleDeleteDetalleProducto = (idItem) => {
    let totalKlgProductoFinal = 0
    let totalUnidadesProductoFinal = 0
    // filtramos el elemento eliminado
    const dataDetalleProductoFinalProduccion = prodDetProdc.filter(
      (element) => {
        if (element.idProdFin !== idItem) {
          return true
        } else {
          totalKlgProductoFinal = element.canKlg
          totalUnidadesProductoFinal = element.canUnd
          return false
        }
      }
    )

    const dataDetalleRequisicionProduccion = reqDetProdc.filter((element) => {
      if (element.idProdFin !== idItem) {
        return true
      } else {
        return false
      }
    })

    // descontamos del total acumulado de klg
    setcantidadLoteProduccion({
      ...cantidadLoteProduccion,
      klgTotalLoteProduccion: parseFloat(
        klgTotalLoteProduccion - totalKlgProductoFinal
      ),
      totalUnidadesLoteProduccion: parseInt(
        totalUnidadesLoteProduccion - totalUnidadesProductoFinal
      )
    })

    // lo insertamos en el detalle
    setproduccionLote({
      ...produccionLote,
      prodDetProdc: dataDetalleProductoFinalProduccion,
      reqDetProdc: dataDetalleRequisicionProduccion
    })
  }

  const crearProduccionLote = async () => {
    // actualizamos los valores correspondientes a klg y total de unidades
    const formatProduccionLote = {
      ...produccionLote,
      totalUnidadesLoteProduccion,
      klgTotalLoteProduccion
    }

    const resultPeticion = await createProduccionLoteWithRequisiciones2(
      formatProduccionLote
    )
    console.log("El formato que se esta enviando a produccion es :")
    console.log(formatProduccionLote)

    const { message_error, description_error } = resultPeticion

    if (message_error.length === 0) {
      // regresamos a la anterior vista
      onNavigateBack()
    } else {
      // hubo error en la insercion
      setfeedbackMessages({
        style_message: 'error',
        feedback_description_error: description_error
      })
      handleClickFeeback()
    }
  }

  // SUBMIT FORMULARIO DE REQUISICION (M-D)
  const handleSubmitProduccionLote = (e) => {
    e.preventDefault()
    if (
      codLotProd.length === 0 ||
      idProdt === 0 ||
      idProdTip === 0 ||
      fecProdIniProg.length === 0 ||
      fecProdFinProg.length === 0
    ) {
      let advertenciaOrdenProduccion = ''
      if (codLotProd.length === 0) {
        advertenciaOrdenProduccion +=
          'No se ha proporcionado el código de lote\n'
      }
      if (idProdt === 0) {
        advertenciaOrdenProduccion += 'No se ha proporcionado el subproducto\n'
      }
      if (idProdTip === 0) {
        advertenciaOrdenProduccion +=
          'No se ha proporcionado el tipo de producción\n'
      }
      if (klgLotProd <= 0) {
        advertenciaOrdenProduccion +=
          'Se debe proporcionar un peso mayor a 0 para buscar la formula\n'
      }
      if (canLotProd <= 0) {
        advertenciaOrdenProduccion +=
          'Se debe proporcionar una cantidad mayor a 0\n'
      }
      if (fecProdIniProg.length === 0) {
        advertenciaOrdenProduccion +=
          'Se debe proporcionar una fecha de inicio programado\n'
      }
      if (fecProdFinProg.length === 0) {
        advertenciaOrdenProduccion +=
          'Se debe proporcionar una fecha de fin programado\n'
      }

      // Mostramos el feedback
      setfeedbackMessages({
        style_message: 'warning',
        feedback_description_error: advertenciaOrdenProduccion
      })
      handleClickFeeback()
    } else {
      let advertenciaSubProductos = ''
      // solo los lotes de subproductos no tienen detalle de presentaciones finales
      if (
        idProdTip !== 5 &&
        (prodDetProdc.length === 0 || reqDetProdc.length === 0)
      ) {
        advertenciaSubProductos +=
          'Solo los lotes de subproducto no pueden tener detalle de presentaciones finales\n'

        // Mostramos el feedback
        setfeedbackMessages({
          style_message: 'warning',
          feedback_description_error: advertenciaSubProductos
        })
        handleClickFeeback()
      } else {
        crearProduccionLote()
      }
    }
  }
  const [step, setStep] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleNextStep = () => {
    setStep(step + 1);
  };
  // Funciones para abrir y cerrar el modal
  const openModal = (e) => {
    e.preventDefault(); // Evita que la página se recargue
    setIsModalOpen(true); // Abrir el modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Cerrar el modal
  };

  return (
    <>
      <div className="container-fluid mx-3">
        <h1 className="mt-4 text-center">Crear Orden de Produccion por Procesos</h1>

        {step === 1 && (
          <div className="row mt-4 mx-4">
            <div className="card d-flex">
              <h6 className="card-header">Datos de Producción</h6>
              <div className="card-body">
                <form>
                  <div className="mb-3 row">
                    <div className="col-md-2">
                      <label htmlFor="nombre" className="form-label">
                        <b>Número de Lote</b>
                      </label>
                      <input
                        type="text"
                        name="codLotProd"
                        onChange={handledForm}
                        value={codLotProd}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-1">
                      <button
                        onClick={openModal}
                        className="btn btn-primary d-flex justify-content-center align-items-center"
                        style={{ marginTop: '32px' }} // Opcional, para asegurar que el botón ocupe el espacio disponible
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                        </svg>
                      </button>
                    </div>
                    <div className="col-md-6 me-4">
                      <label htmlFor="nombre" className="form-label">
                        <b>Producto Intermedio</b>
                      </label>
                      <FilterProductoProduccionDynamic
                        defaultValue={idProdt}
                        

                        onNewInput={onAddProductoProduccion}
                      />
                    </div>
                    <div className="col-md-1">
                      <label htmlFor="nombre" className="form-label">
                        <b>Peso de Lote</b>
                      </label>
                      <input
                        type="number"
                        name="canLotProd"
                        onChange={(e) => {
                          const { name, value } = e.target

                          let data = 0
                          if (value <= _klgLotProd) {
                            data = value
                          } else {
                            data = klgLotProd
                            setfeedbackMessages({
                              style_message: 'error',
                              feedback_description_error:
                                'la cantidad de peso no puede ser mayor a la cantidad disponible'
                            })
                            handleClickFeeback()
                          }

                          setproduccionLote({
                            ...produccionLote,
                            [name]: data
                          })
                        }}
                        value={canLotProd}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-1">
                      <label htmlFor="nombre" className="form-label">
                        <b>Can dis Lote</b>
                      </label>
                      <input
                        type="text"
                        name="codLotProd"
                        onChange={handledForm}
                        value={_klgLotProd - canLotProd}
                        className="form-control"
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="mb-3 row d-flex align-items-center">
                    <div className="col-md-4">
                      <label htmlFor="nombre" className="form-label">
                        <b>Tipo de Producción</b>
                      </label>
                      <FilterTipoProduccionDynamic
                        readOnly
                        onNewInput={onAddTipoProduccion}
                        defaultValue={idProdTip}
                      />
                    </div>
                    <div className="col-md-6 me-6">
                      <label htmlFor="nombre" className="form-label">
                        <b>Fecha Vencimiento Lote</b>
                      </label>
                      <br />
                      <FechaPickerYear
                        onNewfecEntSto={onAddFechaVencimientoLoteProduccion}
                        date={fecVenLotProd}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* DATOS DE PROGRAMACION */}
            <div className="card d-flex mt-4">
              <h6 className="card-header">Datos de Programación</h6>
              <div className="card-body">
                <div className="mb-3 row">
                  <div className="col-md-3">
                    <label htmlFor="nombre" className="form-label">
                      <b>Fecha de Inicio Programado</b>
                    </label>
                    <br />
                    <FechaPicker onNewfecEntSto={onAddFechaInicioProgramado} />
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="nombre" className="form-label">
                      <b>Fecha de Fin Programado</b>
                    </label>
                    <br />
                    <FechaPicker onNewfecEntSto={onAddFechaFinProgramado} />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="nombre" className="form-label">
                      <b>Observaciones</b>
                    </label>
                    <textarea
                      value={obsProd}
                      name="obsProd"
                      onChange={handledForm}
                      className="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            {/* DATOS DEL DETALLE */}

          </div>
        )}
        {/* Modal */}
        {isModalOpen && (
          <div className="modal-overlay"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }} // Estilos en línea para el overlay del modal
          >
            <div className="modal-content"
              style={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '10px',
                width: '400px',
                textAlign: 'center',
              }} // Estilos en línea para
            >
              <div className="row mt-12 mx-12">
                <div className="card d-flex">
                  <h6 className="card-header">Datos del lote de produccion</h6>
                  <div className="card-body">
                    <div className="mb-12 row">
                      <label htmlFor="nombre" className="col-sm-4 col-form-label">
                        Producto
                      </label>
                      <div className="col-md-8"
                      style={{marginBottom: '25px'}}>
                        <FilterProductoProduccion
                              // onNewInput={onAddProductoIntermedio}
                            />
                        {/* {produccionLote.idProd === 0
                          ? (
                            // Ingresamos idMol = 51 para traer los datos de polvos
                            <FilterProductoProduccion
                              onNewInput={onAddProductoIntermedio}
                            />
                          )
                          : (
                            <input
                              disabled
                              value={produccionLote.nomProd}
                              className="form-control"
                            />
                          )} */}
                      </div>
                    </div>

                    <div className="mb-12 row">
                      <label htmlFor="categoria" className="col-sm-4 col-form-label">
                        Numero Lote
                      </label>
                      <div className="col-md-8">
                        <input
                          type="number"
                          name="codLotProd"
                          autoComplete="off"
                          onChange={(e) => {
                            const { name, value } = e.target
                            // setProduccionLote({
                            //   ...produccionLote,
                            //   [name]: value
                            // })
                          }}
                          // value={codLotProd}
                          className="form-control"
                          onWheel={(e) => e.target.blur()}
                        />
                      </div>
                    </div>
                    <div className="mb-12 row">
                      <label htmlFor="categoria" className="col-sm-4 col-form-label">
                        Cantidad Lote
                      </label>
                      <div className="col-md-8">
                        <input
                          type="number"
                          name="canLotProd"
                          // onChange={onChangeCantidadLote}
                          // value={canLotProd}
                          className="form-control"
                          onWheel={(e) => e.target.blur()}
                        />
                      </div>
                    </div>
                    <div className="mb-12 row">
                      <label htmlFor="categoria" className="col-sm-4 col-form-label">
                        Peso programado
                      </label>
                      <div className="col-md-8">
                        <input
                          type="number"
                          name="klgLotProd"
                          disabled
                          // value={klgLotProd}
                          className="form-control"
                        // onWheel={(e) => e.target.blur()}
                        />
                      </div>
                    </div>
                    <div className="mb-12">
                      <FormControlLabel
                        label="¿Es lote de subproducto?"
                        labelPlacement='start'
                        className='me-0 ms-0'
                        control={
                          <Checkbox
                            // checked={esSubProd}
                            // onChange={onChangeCheckboxSubProducto}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card d-flex row mt-12 mx-12">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6 d-flex justify-content-start">
                      <button onClick={closeModal} className="btn btn-primary">
                        Guardar
                      </button>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                      <button onClick={closeModal} className="btn btn-secondary">
                        Cerrar
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        )}

        {step === 2 && (
          <div>
            <div className="card d-flex mt-4">
              <h6 className="card-header">Detalle Presentaciones Finales</h6>
              <div className="card-body">
                <form className="row mb-4 mt-4 d-flex flex-row justify-content-start align-items-end">
                  {/* AGREGAR PRODUCTO */}
                  <div className="col-md-5">
                    <label className="form-label">Presentación Final</label>
                    {/* <FilterAllProductos onNewInput={onProductoId} /> */}
                    <FilterPresentacionFinalDynamic
                      defaultValue={productoLoteProduccion.idProdFin}
                      onNewInput={onAddProductoFinalLoteProduccion}
                      idProdt={idProdt}
                    />
                  </div>
                  {/* KILOGRAMOS DE LOTE ASIGNADOS */}
                  <div className="col-md-2">
                    <label className="form-label">Cantidad Lote (KG)</label>
                    <TextField
                      // type="number"
                      autoComplete="off"
                      size="small"
                      type="number"
                      name="cantidadDeLote"
                      value={productoLoteProduccion.cantidadDeLote}
                      onChange={handleInputsProductoFinalLote}
                    />
                  </div>

                  {/* CANTIDAD DE PRRODUCTOS FINALES ESPERADOS
               */}

                  <div className="col-md-2">
                    <label className="form-label">Cantidad Producto</label>
                    <TextField
                      // type="number"
                      autoComplete="off"
                      size="small"
                      type="number"
                      name="cantidadDeProducto"
                      value={productoLoteProduccion.cantidadDeProducto}
                      onChange={handleInputsProductoFinalCantidad}
                    />
                  </div>

                  {/* BOTON AGREGAR PRODUCTO */}
                  <div className="col-md-3 d-flex justify-content-end align-self-center ms-auto">
                    <button
                      onClick={handleAddProductoProduccionLote}
                      className="btn btn-primary"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-circle-fill me-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                      </svg>
                      Agregar
                    </button>
                  </div>
                </form>
                {/* PRODUCTOS FINALES O SUBPRODUCTOS */}
                <Paper>
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow
                          sx={{
                            '& th': {
                              color: 'rgba(96, 96, 96)',
                              backgroundColor: '#f5f5f5'
                            }
                          }}
                        >
                          <TableCell align="left" width={20}>
                            <b>Prod-Asociado</b>
                          </TableCell>
                          <TableCell align="left" width={200}>
                            <b>Nombre</b>
                          </TableCell>
                          <TableCell align="left" width={20}>
                            <b>U.M</b>
                          </TableCell>
                          <TableCell align="left" width={150}>
                            <b>Unidades</b>
                          </TableCell>
                          <TableCell align="left" width={150}>
                            <b>Peso Lote (kg)</b>
                          </TableCell>
                          <TableCell align="left" width={150}>
                            <b>Acciones</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {prodDetProdc.map((row, i) => {
                          return (
                            <RowEditDetalleProductosFinales
                              key={row.idProdFin}
                              detalle={row}
                              onDeleteItemProductoFinal={
                                handleDeleteDetalleProducto
                              }
                            />
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                {/* DETALLES DE LA CANTIDAD */}
                <div className="mt-4 d-flex justify-content-end align-items-center">
                  <p className="me-4 p-2 bg-dark-subtle">
                    <b>Total Unidades: </b>
                    {totalUnidadesLoteProduccion}
                  </p>
                  <p className="p-2 bg-danger-subtle">
                    <b>Total Peso: </b>
                    {klgTotalLoteProduccion} / {klgLotProd}
                  </p>
                </div>
              </div>
            </div>
            <div className="card d-flex mt-4">
              <h6 className="card-header">Detalle de las Requisiciones</h6>
              <div className="card-body">
                {/* AÑADIR PRODUCTOS ADICICONALES */}
                <form className="row mb-4 mt-4 d-flex flex-row justify-content-start align-items-end">
                  {/* AGREGAR PRODUCTO */}
                  <div className="col-md-5">
                    <label className="form-label">
                      Envases, Embalajes u otros Materiales
                    </label>
                    {/* <FilterAllProductos onNewInput={onProductoId} /> */}
                    <FilterAllProductosDynamic
                      onNewInput={onAddProductoRequisicionLoteProduccion}
                    />
                  </div>
                  {/* AGREGAR AREA */}
                  <div className="col-md-2">
                    <label className="form-label">Área Destino</label>
                    <FilterAreaEncargada
                      onNewInput={handleAreaIdProductoRequisicion}
                    />
                  </div>
                  {/* KILOGRAMOS DE LOTE ASIGNADOS */}
                  <div className="col-md-2">
                    <label className="form-label">Cantidad</label>
                    <TextField
                      size="small"
                      type="number"
                      name="cantidadRequisicion"
                      onChange={handleInputsProductosRequisicion}
                    />
                  </div>
                  {/* BOTON AGREGAR PRODUCTO */}
                  <div className="col-md-3 d-flex justify-content-end align-self-center ms-auto">
                    <button
                      onClick={handleAddProductoRequisicionLote}
                      className="btn btn-primary"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-circle-fill me-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                      </svg>
                      Agregar
                    </button>
                  </div>
                </form>
                {/* DETALLE DE ENVASADO */}
                <div className="card text-bg-success d-flex mt-3">
                  <h6 className="card-header">Detalle Envasado</h6>
                  <div className="card-body">
                    <Paper>
                      <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow
                              sx={{
                                '& th': {
                                  color: 'rgba(96, 96, 96)',
                                  backgroundColor: '#f5f5f5'
                                }
                              }}
                            >
                              <TableCell align="left" width={20}>
                                <b>Prod-Asociado</b>
                              </TableCell>
                              <TableCell align="left" width={230}>
                                <b>Nombre</b>
                              </TableCell>
                              <TableCell align="left" width={20}>
                                <b>U.M</b>
                              </TableCell>
                              <TableCell align="left" width={20}>
                                <b>Unidad</b>
                              </TableCell>
                              <TableCell align="left" width={120}>
                                <b>Total</b>
                              </TableCell>
                              <TableCell align="left" width={150}>
                                <b>Acciones</b>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {reqDetProdc.map((row, i) => {
                              if (row.idAre === 5) {
                                return (
                                  <RowEditDetalleRequisicionProduccion
                                    key={`${row.idProd}-${i}`}
                                    detalle={row}
                                    type="number"
                                    onDeleteItemRequisicion={
                                      handleDeleteItemRequisicionProduccion
                                    }
                                    onChangeItemDetalle={
                                      handleEditItemRequisicionProduccion
                                    }
                                    onValidate={onValidate}
                                  />
                                )
                              } else {
                                return null
                              }
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                </div>
                {/* DETALLE DE ENCAJONADO */}
                <div className="card text-bg-warning d-flex mt-3">
                  <h6 className="card-header">Detalle Encajado</h6>
                  <div className="card-body">
                    <Paper>
                      <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow
                              sx={{
                                '& th': {
                                  color: 'rgba(96, 96, 96)',
                                  backgroundColor: '#f5f5f5'
                                }
                              }}
                            >
                              <TableCell align="left" width={20}>
                                <b>Prod-Asociado</b>
                              </TableCell>
                              <TableCell align="left" width={230}>
                                <b>Nombre</b>
                              </TableCell>
                              <TableCell align="left" width={20}>
                                <b>U.M</b>
                              </TableCell>
                              <TableCell align="left" width={20}>
                                <b>Unidad</b>
                              </TableCell>
                              <TableCell align="left" width={120}>
                                <b>Total</b>
                              </TableCell>
                              <TableCell align="left" width={150}>
                                <b>Acciones</b>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {reqDetProdc.map((row, i) => {
                              if (row.idAre === 6) {
                                return (
                                  <RowEditDetalleRequisicionProduccion
                                    key={`${row.idProd}-${i}`}
                                    detalle={row}
                                    onDeleteItemRequisicion={
                                      handleDeleteItemRequisicionProduccion
                                    }
                                    onChangeItemDetalle={
                                      handleEditItemRequisicionProduccion
                                    }
                                    onValidate={onValidate}
                                  />
                                )
                              } else {
                                return null
                              }
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="btn-toolbar mt-4 ms-4">
          {step > 1 && (
            <button
              type="button"
              onClick={() => setStep(step - 1)}
              className="btn btn-secondary me-2"
            >
              Volver
            </button>
          )}
          {step === 1 && (
            <button
              type="button"
              onClick={() => window.close()}
              className="btn btn-secondary me-2"
            >
              Cancelar
            </button>
          )}
          {step < 2 && (
            <button
              type="button"
              onClick={handleNextStep}
              className="btn btn-primary"
            >
              Siguiente
            </button>
          )}
          {step === 2 && (
            <button
              type="submit"
              onClick={handleSubmitProduccionLote}
              className="btn btn-primary"
            >
              Guardar
            </button>
          )}
        </div>
      </div >



      {/* FEEDBACK AGREGAR MATERIA PRIMA */}
      < Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }
        }
        open={feedbackCreate}
        autoHideDuration={6000}
        onClose={handleCloseFeedback}
      >
        <Alert
          onClose={handleCloseFeedback}
          severity={style_message}
          sx={{ width: '100%' }}
        >
          <Typography whiteSpace={'pre-line'}>
            {feedback_description_error}
          </Typography>
        </Alert>
      </Snackbar >
    </>
  )
}
