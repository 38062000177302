import { TableCell, TableRow } from '@mui/material'
import React, { useState } from 'react'
import { RowGetProduccionLoteDesmedros } from './RowGetProduccionLoteDesmedros'
import iconDevoluciones from '../../../../src/assets/icons/devoluciones.png'

export const RowGetProduccionLote = ({ detalle }) => {
  const [mostrarDetalle, setMostrarDetalle] = useState(false)
  const toggleDetalle = () => {
    setMostrarDetalle(!mostrarDetalle)
  }
  return (
    <>
      <TableRow>
        <TableCell>{detalle.codLotProd}</TableCell>
        <TableCell>{detalle.numop}</TableCell>
        <TableCell>{detalle.nomProd}</TableCell>
        <TableCell>{detalle.fecProdIni}</TableCell>
        <TableCell align="center">
          <span
            className={
              detalle.idProdEst === 1
                ? 'badge text-bg-success'
                : detalle.idProdEst === 6
                  ? 'badge text-bg-warning'
                  : 'badge text-bg-danger'
            }
          >
            {detalle.desEstPro}
          </span>
        </TableCell>
        <TableCell>
          <button
            className="btn btn-outline-secondary me-2"
            title="Agregar devoluciones"
            onClick={() => {
              window.open(
                `/calidad/produccion-lote/crear?idLotProdc=${detalle.id}`,
                '_blank'
              )
            }}
          >
            <img
              alt="Boton devoluciones"
              src={iconDevoluciones}
              height={25}
              width={25}
            />
          </button>
        </TableCell>
      </TableRow>
      {mostrarDetalle && (
        <TableRow>
          <TableCell colSpan={6} >
            <RowGetProduccionLoteDesmedros
              detalleProduccionLoteDesmedros={detalle.req_dev_det}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
