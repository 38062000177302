import React, { useState, useEffect } from 'react';
import { BootstrapDialog2 } from '../BootstrapDialog2';
import { Button, DialogActions, DialogContent, DialogTitle, TextField, Modal, Box } from '@mui/material';
import { parserInputQuantity } from '../../utils/functions/validations';
import { alertWarning } from '../../utils/alerts/alertsCustoms';
import { FilterMateriaPrimaSeleccionada } from '../ReferencialesFilters/Producto/FilterMateriaPrimaSeleccionado';
import FechaHoraPicker from '../Fechas/FechaHoraPicker';
import { FilterProcesosSelect } from '../ReferencialesFilters/Producto/FilterProcesosSelect';
import axios from 'axios'; // Importa axios
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export const CustomDialogUpdateMultiOperation = ({ detalle, onUpdateOperation, onUpdateEndDataOperation, disabled, formato }) => {
  const [requisicion, setRequisicion] = useState({})
  const [open, setOpen] = useState(false)
  const [cantidadPersonas, setCantidadPersonas] = useState(0.0)
  const [fechaInicio, setFechaInicio] = useState(null)
  const [fechaFin, setFechaFin] = useState(null)
  const [tipoProceso, setTipoProceso] = useState('')
  const [cantidadnoDeshidratada, setCantidadnoDeshidratada] = useState(0.0)

  //constantes para el modal
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [newFechaFin, setNewFechaFin] = useState("");
  const [idProces, setIdPorces] = useState("")
  const handleClickOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  //Modal para la edicion de la fecha fin en caso se necesite mas funcionalidades se implemetara
  const handleOpenModal = (row) => {
    setNewFechaFin(row.fecha_fin); // Asegúrate de que row.fecha_fin tenga el formato adecuado (yyyy-mm-dd)
    setIdPorces(row.id)
    setOpenModal(true); // Abrir el modal
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRow(null);
    setNewFechaFin('');
  }

  const onChangeDateUpdateEndData = (newFechaFin) => {
    setNewFechaFin(newFechaFin)
  }

  const handleSave = () => {
    // Aquí puedes guardar la nueva fecha_fin, por ejemplo, actualizando el estado o llamando a una API
    // console.log(`Fecha Fin actualizada para ${selectedRow.descripcion}: ${newFechaFin}`);
    onUpdateEndDataOperation([detalle, newFechaFin, idProces]);
    handleCloseModal();
  }

  const onChangeDateStartData = (newDate) => {
    setFechaInicio(newDate)
  }

  const onChangeDateEndData = (newDate) => {
    setFechaFin(newDate)
  }

  const handleCantidadnoDeshidratadaChange = (event) => {
    const { value } = event.target
    setCantidadnoDeshidratada(value)
  }

  const handleCantidadPersonasChange = (event) => {
    const { value } = event.target
    setCantidadPersonas(value)
  }

  const handleTipoProcesoChange = (newTipoProceso) => {
    setTipoProceso(newTipoProceso)
    console.log(tipoProceso.tipoPro)
  }

  const handleCheckValues = () => {
    const parserQuantity = parserInputQuantity(cantidadPersonas)

    if (isNaN(parserQuantity) || parserQuantity <= 0) {
      alertWarning('Debes ingresar una cantidad válida y mayor a 0 para personas')
      return
    }

    if (new Date(fechaInicio) > new Date(fechaFin)) {
      alertWarning('La fecha de inicio no puede ser posterior a la fecha de fin')
      return
    }

    if (!tipoProceso) {
      alertWarning('Debes seleccionar un tipo de proceso')
      return
    }

    // Si todas las validaciones pasan, llama a la actualización
    onUpdateOperation([detalle, cantidadPersonas, fechaInicio, fechaFin, tipoProceso, cantidadnoDeshidratada]);
    handleClose();
  }

  return (
    <div>
      <button
        className='btn btn-success me-2'
        onClick={handleClickOpen}
        disabled={disabled}
        title='Ingresar procesos'
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M3 12.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z" />
        </svg>
      </button>

      <BootstrapDialog2
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2, fontWeight: 'bold' }} id="customized-dialog-title">
          Ingresar Operaciones
        </DialogTitle>

        <DialogContent dividers>
          {/* Tipo de Proceso */}
          <span className="d-block fw-semibold mb-2">Tipo de Proceso:</span>
          <FilterProcesosSelect
            className="col-12"
            value={tipoProceso}
            onNewInput={handleTipoProcesoChange}
          />

          {/* Fechas de Inicio y Fin */}
          {/* Fechas de Inicio y Fin */}
          <div className="d-flex">
            <div className="me-2" style={{ flex: 1 }}>
              <b className="d-block fw-semibold text-danger mb-1">Fecha Inicio</b>
              <FechaHoraPicker
                onNewfecEntSto={onChangeDateStartData}
                label="Desde"
                fullWidth
                style={{ width: '100%' }}
              />
            </div>

            <div style={{ flex: 1 }}>
              <b className="d-block fw-semibold text mb-1">Fecha Fin</b>
              <FechaHoraPicker
                onNewfecEntSto={onChangeDateEndData}
                label="Hasta"
                fullWidth
                style={{ width: '100%' }}
              />
            </div>
          </div>

          {/* Cantidad de Personas y Cantidad Deshidratada */}
          <div className="d-flex">
            <div className="me-2" style={{ flex: 1 }}>
              <b className="d-block fw-semibold text-danger mb-1">Cantidad de personas</b>
              <TextField
                value={cantidadPersonas}
                onChange={handleCantidadPersonasChange}
                size="small"
                type="number"
                autoComplete="off"
                fullWidth
                onWheel={(e) => e.target.blur()}
              />
            </div>

            {tipoProceso.tipoPro === 3 && (
              <div style={{ flex: 1 }}>
                <b className="d-block fw-semibold text-danger mb-1">Peso Ingresado(material no deshidratado)</b>
                <TextField
                  value={cantidadnoDeshidratada}
                  onChange={handleCantidadnoDeshidratadaChange}
                  size="small"
                  type="number"
                  autoComplete="off"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                />
              </div>
            )}
          </div>


          {/* Detalle de Ingresos */}
          <div className="mt-3 me-3">
            <b className="d-block fw-semibold mb-1">Detalle de Ingresos:</b>
            <div className="col-md-12">
              <Paper>
                <TableContainer>
                  <Table sx={{ minWidth: 535, minHeight: 100 }} aria-label="customized table">
                    <TableHead>
                      <TableRow
                        sx={{
                          '& th': {
                            color: 'rgba(96, 96, 96)',
                            backgroundColor: '#F0F0F0',
                          },
                        }}
                      >
                        <TableCell align="left" width={80}><b>Procesos</b></TableCell>
                        <TableCell align="left" width={80}><b>Fecha início</b></TableCell>
                        <TableCell align="left" width={80}><b>Fecha fin</b></TableCell>
                        <TableCell align="left" width={40}><b>Cant. perso</b></TableCell>
                        <TableCell align="left" width={40}><b>Peso MP</b></TableCell>
                        <TableCell align="left" width={40}><b></b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formato.detalle2.map((row, i) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">{row.descripcion}</TableCell>
                          <TableCell align="left">{row.fecha_ini}</TableCell>
                          <TableCell align="left">{row.fecha_fin}</TableCell>
                          <TableCell align="center">{row.cantPerso}</TableCell>
                          <TableCell align="center">{row.klgNoDes}</TableCell>
                          <TableCell align="center">
                            <button
                              className="btn btn-success ms-2"
                              onClick={() => handleOpenModal(row)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-pencil-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                              </svg>
                            </button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    {/* Modal para editar la fecha fin */}
                    <Modal
                      open={openModal}
                      onClose={handleCloseModal}
                      aria-labelledby="edit-modal-title"
                      aria-describedby="edit-modal-description"
                    >
                      <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        p: 4,
                        boxShadow: 24,
                        borderRadius: 2
                      }}>
                        {/* Título con separación */}
                        <Box sx={{ mb: 3, borderBottom: '1px solid #e0e0e0', pb: 1 }}>
                          <h4 id="edit-modal-title" style={{ margin: 0 }}>Editar Fecha Fin</h4>
                        </Box>

                        {/* Selector de fecha */}
                        <FechaHoraPicker
                          label="Nueva Fecha Fin"
                          onNewfecEntSto={onChangeDateUpdateEndData}
                          type="date"
                          value={newFechaFin}
                          onChange={(e) => setNewFechaFin(e.target.value)}
                          fullWidth
                          sx={{ mt: 2, mb: 3 }}
                        />

                        {/* Botones alineados a cada lado */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                          <Button
                            color="secondary"
                            onClick={handleCloseModal}
                            sx={{color: 'white', backgroundColor: '#B0B0B0', '&:hover': { backgroundColor: '#A0A0A0' } }}
                          >
                            Cancelar
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                          >
                            Guardar
                          </Button>
                        </Box>
                      </Box>
                    </Modal>

                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
          <Button color="error" onClick={handleCheckValues}>Aceptar</Button>
        </DialogActions>
      </BootstrapDialog2>
    </div>
  );
};
