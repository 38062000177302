import { ListReportes } from './ListReportes'
import { ReporteStockTotal } from './ReporteStockTotal'
import { ReporteEntradaSalidaStock } from './ReporteEntradaSalidaStock'
import { ReporteProductoFinalLotes } from './ReporteProductoFinalLotes'
import { ReporteEntradaMerma } from './ReporteEntradaMerma'
import { ReporteTrazabilidadProductoFinal } from './ReporteTrazabilidadProductoFinal'
// Control de acceso para usuarios
import ProtectedRoute from '../../components/componentes-auth/ProtectedRoute'
export const RouterReportesAlmacen = [
  {
    path: '',
    element: <ListReportes />
  },
  {
    path: 'reporte-stock-total',
    element: <ReporteStockTotal />
  },
  {
    path: 'reporte-entrada-salida-stock',
    element: <ReporteEntradaSalidaStock />
    // element: (
    //   <ProtectedRoute>
    //     <ReporteEntradaSalidaStock />
    //   </ProtectedRoute>
    // )
  },
  {
    path: 'reporte-producto-final-lotes',
    element: <ReporteProductoFinalLotes />
    // element: (
    //   <ProtectedRoute>
    //     <ReporteProductoFinalLotes />
    //   </ProtectedRoute>)
  },
  {
    path: 'reporte-entrada-merma',
    element: (
      <ProtectedRoute>
        <ReporteEntradaMerma />
      </ProtectedRoute>)
  },
  {
    path: 'reporte-producto-final-stock',
    element: <ReporteTrazabilidadProductoFinal />
    // element: (
    //   <ProtectedRoute>
    //     <ReporteTrazabilidadProductoFinal />
    //   </ProtectedRoute>)
  }
]
