import { ViewLoteMolienda } from './ViewLoteMolienda'
import { ListRequisicionesDeshidratado } from './ListRequisicionesDeshidratado'
import { AgregarSubProducto } from './AgregarSubProducto'

export const RouterRequisicionDeshidratado = [
  {
    path: '',
    element: <ListRequisicionesDeshidratado />
  },
  {
    path: 'view/:idProdc/:idReq',
    element: <ViewLoteMolienda />
  },
  {
    path: 'agregar-subproducto/:idReq',
    element: <AgregarSubProducto />
  }
]
