import { AgregarAgregacionV2 } from './AgregarAgregacionV2'
import { RequisicionAgregaciones } from './RequisicionAgregaciones'
import { AgregarAgregacionTransformacion } from './AgregarAgregacionTransformacion'

export const RouterAlmacenAgregaciones = [
  {
    path: 'crear',
    element: <AgregarAgregacionV2 />
  },
  {
    path: 'atender-requisiciones',
    element: <RequisicionAgregaciones />
  },
  {
    path: 'agregacion-transformacion',
    element: <AgregarAgregacionTransformacion />
  }
]
