import { TableCell, TableRow } from '@mui/material'
import React, { useState } from 'react'
import { RowSolicitudDevolucionCalidadDetalle } from './RowDetalleOperacionSolicitudDevolucion'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import Modal from '@mui/material/Modal';

export const RowOperacionSolicitudDevolucion = ({ detalle }) => {
  const StyledModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }))

  const ImagePreview = styled('img')(({ theme }) => ({
    maxWidth: '90%',
    maxHeight: '90%',
  }))
  const [mostrarDetalle, setMostrarDetalle] = useState(false)
  const toggleDetalle = () => {
    setMostrarDetalle(!mostrarDetalle)
  }
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (url) => {
    setSelectedImage(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage('');
  };
  console.log('el detalle es ', detalle)
  return (
    <>
      <TableRow>
        <TableCell>{detalle.invoice_ser}</TableCell>
        <TableCell>{detalle.invoice_numb}</TableCell>
        <TableCell align="center">
          <span
            className={
              detalle.idEstadoSoli === 1
                ? 'badge text-bg-danger'
                : detalle.idEstadoSoli === 2
                  ? 'badge text-bg-warning'
                  : 'badge text-bg-success'
            }
          >
            {detalle.estado}
          </span>
        </TableCell>
        <TableCell>{detalle.detalle}</TableCell>
        <TableCell>{detalle.contact}</TableCell>
        <TableCell>{detalle.fecha_reclamo}</TableCell>
        <TableCell>
          <button
            className="btn btn-link ms-auto" // Utiliza ms-auto para alinear a la derecha
            onClick={toggleDetalle}
          >
            {mostrarDetalle
              ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-caret-up-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                </svg>
              )
              : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-caret-down-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              )}
          </button>
        </TableCell>
      </TableRow>
      {mostrarDetalle && (

        <TableRow>
          <TableCell colSpan={4}>
            <RowSolicitudDevolucionCalidadDetalle
              detalleDevolucionesCalidad={detalle.detalles}
            />
          </TableCell>
          <TableCell colSpan={4}>
            {/* <Grid container spacing={2}>
              {detalle.imagenes.map((imagen, index) => (
                <Grid item xs={6} key={index}>
                  <img
                    src={`http://localhost/emaprod/Backend/cliente/${imagen.url_imagen}`}
                    alt={`Imagen ${index}`}
                    style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                    onClick={() => handleImageClick(`http://localhost/emaprod/Backend/cliente/${imagen.url_imagen}`)}
                  />
                  <samp>{imagen.descripcion}</samp>
                </Grid>
              ))}
            </Grid> */}
            <div style={{ flexBasis: '40%' }}>
              <div className="row">
                {detalle.imagenes.map((imagen, index) => (
                  <div className="col-md-4" key={index}>
                    <div className="card mb-3">
                      <div className="card-body d-flex justify-content-between align-items-center">
                        <div className="me-2">
                          <h6 className="card-title mb-0">Imagen {index + 1}</h6>
                          <small className="text-muted">
                            Tamaño: {(imagen.size / 1024).toFixed(2)} KB
                          </small>
                          <img
                            src={`http://localhost/emaprod/Backend/cliente/${imagen.url_imagen}`}
                            alt={`Imagen ${index}`}
                            className="img-fluid mt-2"
                            style={{ maxHeight: '100px', objectFit: 'cover' }}
                            onClick={() => handleImageClick(`http://localhost/emaprod/Backend/cliente/${imagen.url_imagen}`)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <StyledModal open={open} onClose={handleClose}>
              <ImagePreview src={selectedImage} alt="Vista ampliada" />
            </StyledModal>
          </TableCell>
        </TableRow>

        // <TableRow>
        //   <TableCell colSpan={12} style={{ padding: 0 }}>
        //     <Grid container spacing={2}>
        //       {detalle.imagenes.map((imagen, index) => (
        //         <Grid item xs={6} key={index}>
        //           <img
        //             src={`http://localhost/emaprod/Backend/cliente/${imagen.url_imagen}`}
        //             alt={`Imagen ${index}`}
        //             style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
        //             onClick={() => handleImageClick(`http://localhost/emaprod/Backend/cliente/${imagen.url_imagen}`)}
        //           />
        //         </Grid>
        //       ))}
        //     </Grid>
        //   </TableCell>
        // </TableRow>

        // {/* Modal para la vista ampliada de la imagen */}

      )}
    </>
  )
}
