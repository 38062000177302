import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import HomeAlmacen from '../../pages/HomeAlmacen'

// Componente que protege las rutas
const ProtectedRoute = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [isChecking, setIsChecking] = useState(true) // Nuevo estado para saber si se está verificando

  useEffect(() => {
    const verifyAdminUser = () => {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user && user.idRolUsu === 1) {
        setIsAuthorized(true)
      }
      setIsChecking(false) // Marcamos que la verificación ha terminado
    }
    
    verifyAdminUser()
  }, [])

  // Mientras está verificando, no se redirige ni se muestra nada
  if (isChecking) {
    return null // o puedes devolver un componente de carga
  }

  // Si el usuario no está autorizado, redirige a HomeAlmacen
  if (!isAuthorized) {
    return <Navigate to="/" replace />
  }

  // Si el usuario está autorizado, renderiza el contenido
  return children
}

export default ProtectedRoute
