import { ListLoteProduccion } from './ListLoteProduccion'
import { AgregarDevolucionV2 } from './AgregarDevolucionV2'

export const RouterLoteProduccion = [
  {
    path: '',
    element: <ListLoteProduccion />
  },
  {
    path: 'crear',
    element: <AgregarDevolucionV2 />
  }
]
