import axios from 'axios'
import config from '../../../config'
export const createSolicitudDevolucion = async (body) => {
  const { API_URL } = config;
  const domain = API_URL;
  const path = '/cliente/operacion-devolucion/create_solicitud_operacion_devolucion.php';
  const url = domain + path;

  try {
      const { data } = await axios.post(url, body); // Usar body directamente
      return data;
  } catch (error) {
      console.error('Error creating solicitud de devolucion:', error);
      throw error; // O maneja el error según sea necesario
  }
};
