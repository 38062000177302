import { CreateOperacionDevolucionV2 } from './FormularioOperacionDevolucionV2'
import { CreateOperacionDevolucion } from './FormularioOperacionDevolucion'
import { ListOperacionDevolucionVentas } from './ListOperacionDevolucion'
import { ListOpcionesOperacionDevolucion } from './ListOpcionesOperacionDevolucion'
import {ListCodigosOperacionDevolucionVentas} from './ListCodigosOperacionDevolucion'

export const RouterOperacionDevolucion = [
  {
    path: 'solicitudes-devoluciones',
    element: <ListOperacionDevolucionVentas />
  },
  {
    path: 'crear',
    element: <CreateOperacionDevolucionV2 />
  }
  ,
  {
    path: '',
    element: <ListOpcionesOperacionDevolucion />
  },
  {
    path: 'codigos',
    element: <ListCodigosOperacionDevolucionVentas />
  }
]
