import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

// IMPORTACIONES PARA EL FEEDBACK
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { getEntradaStockCalidadById } from '../../helpers/entradas-stock/getEntradaStockCalidadById'
import { getInfoSolicitudOperacionDevolucion } from '../../helpers/solicitud-devolucion/getInfoSolicitudOperacionDevolucion'
import { CardAtributosCalidadEntrada } from '../../components/entrada-stock/CardAtributosCalidadEntrada'
import { FilterEncargadoCalidad } from '../../../components/ReferencialesFilters/EncargadoCalidad/FilterEncargadoCalidad'
import { createEntradaAtributosCalidad } from '../../helpers/entradas-stock/createEntradaAtributosCalidad'
// import { FilterEstadoCalidad } from '../../../components/ReferencialesFilters/EstadoCalidad/FilterEstadoCalidad'
import { FilterEstadoCalidad } from '../../../components/ReferencialesFilters/EstadoCalidad/FilterEstadoCalidadSolicitud'
import { Typography } from '@mui/material'
import FechaPickerYear from '../../../components/Fechas/FechaPickerYear'
import { updateFechaVencimientoEntradaStock } from '../../helpers/entradas-stock/updateFechaVencimientoEntradaStock'
import { styled } from '@mui/material/styles'
import Modal from '@mui/material/Modal'
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'

// CONFIGURACION DE FEEDBACK
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export const ViewOperacionSolicitudDevolucion = () => {
  const { idEntSto } = useParams()
  const [loading, setLoading] = useState(true)
  const [dataIngoSolicitud, setDataIngoSolicitud] = useState({
    id: 0,
    fecCreSoli: '',
    guiaRemi: '',
    id_client: 0,
    cliente_nombre: '',
    cliente_apellido: '',
    cliente_documento: '',
    cliente_contacto: '',
    nomEncCal: '',
    observacion_calidad: '',
    idCalidadSoli: 0,
    idResponsable: 0,
    id_guia: 0,
    invoice_numb: 0,
    invoice_ser: '',
    observaciones: '',
    telefono: '',
    operacionFacturacion: [],
    detalles: [],
    imagenes: [],
    informacion_calidad: {
      idResEntCal: null,
      idEntCalEst: null,
      obsAccEntCal: '',
      conHigTrans: null
    }
  })
  const {
    id,
    fecCreSoli,
    guiaRemi,
    id_client,
    cliente_nombre,
    cliente_apellido,
    cliente_documento,
    cliente_contacto,
    nomEncCal,
    observacion_calidad,
    idCalidadSoli,
    idResponsable,
    id_guia,
    invoice_numb,
    invoice_ser,
    observaciones,
    telefono,
    operacionFacturacion,
    detalles,
    imagenes,
    informacion_calidad
  } = dataIngoSolicitud
  // const [dataEntradaStockCalidad, setDataEntradaStockCalidad] = useState({
  //   id: 0,
  //   idProd: 0,
  //   nomProd: '',
  //   codProd: '',
  //   idCla: 0,
  //   codProd2: '',
  //   codProd3: '',
  //   desCla: '',
  //   idProv: 0,
  //   nomProv: '',
  //   apeProv: '',
  //   codProv: '',
  //   idEntStoEst: 0,
  //   desEntStoEst: '',
  //   codEntSto: '',
  //   fecEntSto: '',
  //   fecVenEntSto: '',
  //   esSel: 0,
  //   canSel: 0,
  //   canPorSel: 0,
  //   merDis: 0,
  //   merTot: 0,
  //   canTotCom: 0,
  //   canTotEnt: 0,
  //   canTotDis: 0,
  //   canVar: 0,
  //   fecFinSto: '',
  //   etiquetasCards: [],
  //   dataAtributosEntradaCalidad: [],
  //   informacion_valores_atributos: [],
  //   informacion_calidad: {
  //     idResEntCal: null,
  //     idEntCalEst: null,
  //     obsAccEntCal: '',
  //     conHigTrans: null
  //   }
  // })
  // const {
  //   nomProd,
  //   codProd,
  //   codProd2,
  //   idCla,
  //   codProd3,
  //   nomProv,
  //   apeProv,
  //   codProv,
  //   desEntStoEst,
  //   codEntSto,
  //   fecEntSto,
  //   fecVenEntSto,
  //   esSel,
  //   canSel,
  //   canPorSel,
  //   merDis,
  //   merTot,
  //   canTotCom,
  //   canTotEnt,
  //   canTotDis,
  //   canVar,
  //   fecFinSto,
  //   dataAtributosEntradaCalidad,
  //   informacion_valores_atributos,
  //   informacion_calidad
  // } = dataEntradaStockCalidad

  const { idResEntCal, idEntCalEst, obsAccEntCal, conHigTrans, idCalSoli} =
    informacion_calidad
  const StyledModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }))
  const ImagePreview = styled('img')(({ theme }) => ({
    maxWidth: '100%',
    maxHeight: '100%',
  }))
  const [mostrarDetalle, setMostrarDetalle] = useState(false)
  const toggleDetalle = () => {
    setMostrarDetalle(!mostrarDetalle)
  }
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (url) => {
    setSelectedImage(url)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
    setSelectedImage('')
  }

  const onChangeEncargadoEvaluacionCaidad = (value) => {
    const { id } = value
    const formatDataCalidad = {
      ...informacion_calidad,
      idResEntCal: id
    }
    setDataIngoSolicitud({
      ...dataIngoSolicitud,
      informacion_calidad: formatDataCalidad
    })
  }

  const onChangeEstadoEvaluacionCaidad = (value) => {
    const { id } = value
    const formatDataCalidad = {
      ...informacion_calidad,
      idEntCalEst: id
    }
    setDataIngoSolicitud({
      ...dataIngoSolicitud,
      informacion_calidad: formatDataCalidad
    })
  }

  const onChangeObservacionesEvaluacionCalidad = ({ target }) => {
    const { value } = target

    const formatDataCalidad = {
      ...informacion_calidad,
      obsAccEntCal: value
    }
    setDataIngoSolicitud({
      ...dataIngoSolicitud,
      informacion_calidad: formatDataCalidad
    })
  }

  const onChangeCondicionesHigieneTransporte = ({ target }) => {
    const { value } = target

    const formatDataCalidad = {
      ...informacion_calidad,
      conHigTrans: value
    }
    setDataIngoSolicitud({
      ...dataIngoSolicitud,
      informacion_calidad: formatDataCalidad
    })
  }

  // cambiar fecha de vencimiento
  const onChangeDateVencimiento = (newfecEntSto) => {
    setDataIngoSolicitud({
      ...dataIngoSolicitud,
      fecVenEntSto: newfecEntSto
    })
  }

  // ESTADO PARA CONTROLAR EL FEEDBACK
  const [feedbackCreate, setfeedbackCreate] = useState(false)
  const [feedbackMessages, setfeedbackMessages] = useState({
    style_message: '',
    feedback_description_error: ''
  })
  const { style_message, feedback_description_error } = feedbackMessages

  // MANEJADORES DE FEEDBACK
  const handleClickFeeback = () => {
    setfeedbackCreate(true)
  }

  const handleCloseFeedback = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setfeedbackCreate(false)
  }

  // ESTADO PARA BOTON CREAR
  const [disableButton, setdisableButton] = useState(false)

  // funcion para cambiar valor de texto y numero
  const onChangeValoresAlfanumericos = ({ target }, element) => {
    const { value } = target
    const dataAux = dataAtributosEntradaCalidad.map((atributo) => {
      if (element.id === atributo.id) {
        return {
          ...atributo,
          valEntCalAtr: value
        }
      } else {
        return atributo
      }
    })

    // actualizamos la data
    setDataIngoSolicitud({
      ...dataIngoSolicitud,
      dataAtributosEntradaCalidad: dataAux
    })
  }

  // manejador de errores de atributos de calidad
  const handleGuardarAtributos = () => {
    let handledError = ''

    if (idResEntCal === null) {
      handledError += 'Debe agregar información del encargado de evaluación\n'
    }
    if (idEntCalEst === null) {
      handledError += 'Debe agregar información del estado de calidad\n'
    }

    if (handledError.length !== 0) {
      setfeedbackMessages({
        style_message: 'warning',
        feedback_description_error: handledError
      })
      handleClickFeeback()
    } else {
      guardarDatosAtributosCalidad()
    }
  }

  // guardar fecha de vencimiento
  const guardarFechaVencimiento = async () => {
    if (fecVenEntSto) {
      const body = {
        idEntSto: dataIngoSolicitud.id,
        fecVenEntSto
      }
      console.log(body)
      const resultPeticion = await updateFechaVencimientoEntradaStock(body)
      const { message_error, description_error } = resultPeticion
      if (message_error.length === 0) {
        setfeedbackMessages({
          style_message: 'success',
          feedback_description_error:
            'La fecha de vencimiento se actualizó con éxtio'
        })
        handleClickFeeback()
      } else {
        setfeedbackMessages({
          style_message: 'error',
          feedback_description_error: description_error
        })
        handleClickFeeback()
      }
    } else {
      setfeedbackMessages({
        style_message: 'warning',
        feedback_description_error: 'Selecciona una fecha de vencimiento'
      })
      handleClickFeeback()
    }
  }

  // funcion para guardar atributos de calidad asociados a entrada
  const guardarDatosAtributosCalidad = async () => {
    // primero debemos mostrar un dialog que pregunte si quiere habilitarlo para FIFO
    // guardamos informacion de calidad
    // 1. debemos verificar que atributos son para insertar y cuales son para actualizar
    // 2. Si el registro esta vacio:
    // - si ya fue registrado, entonces hablamos de una actualizacion
    // - si no fue registrado; entonces lo quitamos de la data
    // 3. Si el registro esta lleno:
    // - si ya fue registrado, entonces hablamos de una actualizacion
    // - si no fue registrado; entonces hablamos de una nueva insercion

    setdisableButton(true)
    const dataAtributos = dataAtributosEntradaCalidad.map((itemData) => {
      const valueAtributo = itemData.valEntCalAtr.trim()
      // buscamos
      const findElementAtributo = informacion_valores_atributos.find(
        (itemAtributo) => itemData.id === itemAtributo.idProdtAtrCal
      )

      // si ya se creo el atributo
      if (findElementAtributo) {
        // si el valor actual es igual al creado
        if (valueAtributo === findElementAtributo.valEntCalAtr.trim()) {
          return {
            ...itemData,
            valEntCalAtr: valueAtributo,
            action: 'DELETE'
          }
        } else {
          return {
            ...itemData,
            valEntCalAtr: valueAtributo,
            action: 'UPDATE'
          }
        }
      } else {
        // si no se creo el atributo
        // si el valor es vacio
        if (valueAtributo.length === 0) {
          return {
            ...itemData,
            valEntCalAtr: valueAtributo,
            action: 'DELETE'
          }
        } else {
          return {
            ...itemData,
            valEntCalAtr: valueAtributo,
            action: 'CREATE'
          }
        }
      }
    })

    const filterDatosDelete = dataAtributos.filter(
      (element) => element.action !== 'DELETE'
    )

    const formatData = {
      ...dataIngoSolicitud,
      dataAtributosEntradaCalidad: filterDatosDelete
    }

    console.log(formatData)
    const resultPeticion = await createEntradaAtributosCalidad(formatData)
    console.log(resultPeticion)
    const { message_error, description_error } = resultPeticion
    if (message_error.length !== 0) {
      // error al crear los atributos de calidad
      setfeedbackMessages({
        style_message: 'error',
        feedback_description_error: description_error
      })
      handleClickFeeback()
    } else {
      // mostrar exito
      setfeedbackMessages({
        style_message: 'success',
        feedback_description_error: 'Se actualizo con éxito'
      })
      handleClickFeeback()
      // navegamos hacia la anterior vista
      setTimeout(() => {
        window.close()
      }, '1000')
    }
    setdisableButton(false)
  }

  useEffect(() => {
    const traerDatosEntradaStockCalidad = async () => {
      const resultPeticion = await getEntradaStockCalidadById(idEntSto)
      const resultPeticion2 = await getInfoSolicitudOperacionDevolucion(idEntSto)
      // const { result } = resultPeticion
      const { result } = resultPeticion2
      console.log(result)
      // const { informacion_atributos, informacion_valores_atributos } = result
      const { informacion_data_soli, informacion_valores_data_soli } = result
      setDataIngoSolicitud(result)
      setLoading(false)
    }

    traerDatosEntradaStockCalidad()
  }, [idEntSto])

  return (
    <>
      {!loading && (
        <>
          <div className="container-fluid mx-3">
            <h1 className="mt-4 text-center">Datos de calidad de entrada</h1>
            <div className="row mt-4 mx-4">
              {/* DatOS DE PRODUCTO */}
              <div className="card d-flex">
                <h6 className="card-header">Datos de producto</h6>
                <div className="card-body">
                  <div className="mb-3 row">
                    {/* NOMBRE PRODUCTO */}
                    <div className="col-md-4">
                      <label htmlFor="nombre" className="form-label">
                        <b>Cliente</b>
                      </label>
                      <input
                        type="text"
                        disabled={true}
                        value={cliente_contacto || 'No establecido'}
                        className="form-control"
                      />
                    </div>
                    {/* FACTURA  */}
                    <div className="col-md-2">
                      <label htmlFor="nombre" className="form-label">
                        <b>FACTURA</b>
                      </label>
                      <input
                        type="text"
                        disabled={true}
                        value={(invoice_ser, "-", invoice_numb) === null ? 'No establecido' : (invoice_ser + "-" + invoice_numb)}
                        className="form-control"
                      />
                    </div>
                    {/* GUIA ASOCIADA */}
                    <div className="col-md-2">
                      <label htmlFor="nombre" className="form-label">
                        <b>GUIA ASOCIADA</b>
                      </label>
                      <input
                        type="text"
                        disabled={true}
                        value={guiaRemi === null ? 'No establecido' : guiaRemi}
                        className="form-control"
                      />
                    </div>
                    {/* CODIGO EMAPROD */}
                    <div className="col-md-3">
                      <label htmlFor="nombre" className="form-label">
                        <b>FECHA DE SOLICITUD</b>
                      </label>
                      <input
                        type="text"
                        disabled={true}
                        value={fecCreSoli === null ? 'No establecido' : fecCreSoli}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Datos de proveedor */}
              <div className="card d-flex mt-4">
                <h6 className="card-header">Información del reclamo</h6>
                <div className="card-body">
                  <div className="mb-4 row">
                    {/* NOMBRE DE PROVEEDOR */}
                    <div className="col-md-12 mb-3">
                      <label htmlFor="nombre" className="form-label">
                        <b>Observaciones del cliente</b>
                      </label>
                      <input
                        type="text"
                        disabled={true}
                        value={observaciones}
                        className="form-control"
                      />
                    </div>
                    {/* APELLIDO DE PROVEEDOR */}
                    <div className="col-md-12">
                      <label htmlFor="nombre" className="form-label">
                        <b>Imagenes adjuntas</b>
                      </label>
                      <div style={{ flexBasis: '40%' }}>
                        <div className="row">
                          {imagenes.map((imagen, index) => (
                            <div className="col-md-3" key={index}>
                              <div className="card mb-3">
                                <div className="card-body d-flex justify-content-between align-items-center">
                                  <div className="me-2">
                                    <h6 className="card-title mb-0">Imagen {index + 1}</h6>
                                    <small className="text-muted">
                                      Tamaño: {(imagen.size / 1024).toFixed(2)} KB
                                    </small>
                                    <img
                                      src={`http://localhost/emaprod/Backend/cliente/${imagen.url_imagen}`}
                                      alt={`Imagen ${index}`}
                                      className="img-fluid mt-2"
                                      style={{ maxHeight: '100px', objectFit: 'cover' }}
                                      onClick={() => handleImageClick(`http://localhost/emaprod/Backend/cliente/${imagen.url_imagen}`)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <StyledModal open={open} onClose={handleClose}>
                            <ImagePreview src={selectedImage} alt="Vista ampliada" />
                          </StyledModal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Datos de entrada */}
              <div className="card d-flex mt-4">
                <h6 className="card-header">Productos Observados</h6>
                <div className="card-body">
                  <div className="mb-4 row">
                    {/* NOMBRE DE PROVEEDOR */}
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead style={{ backgroundColor: '#FEE7BC' }}>
                          <TableRow>
                            <TableCell>
                              <b>#</b>
                            </TableCell>
                            <TableCell>
                              <b>Producto</b>
                            </TableCell>
                            <TableCell>
                              <b>Cantidad</b>
                            </TableCell>
                            <TableCell>
                              <b>Lote</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {detalles.map((detalle, index) => (
                            <TableRow key={`${detalle.id} - ${index}`}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{detalle.detalle.nomProd}</TableCell>
                              <TableCell>{detalle.detalle.cantObs}</TableCell>
                              <TableCell>{detalle.detalle.codLotProd}</TableCell>

                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>

              <div className="card d-flex mt-4">
                <h6 className="card-header">
                  Datos de encargado de evaluacion
                </h6>
                <div className="card-body">
                  <div className="mb-2 row">
                    <div className="col-md-3">
                      {/* ENCARGADO DE CALIDAD */}
                      <label htmlFor="nombre" className="form-label">
                        <b>Encargado calidad</b>
                      </label>
                      <FilterEncargadoCalidad
                        onNewInput={onChangeEncargadoEvaluacionCaidad}
                        defaultValue={idResEntCal}
                      />
                    </div>
                    {/* ESTADO DE CALIDAD */}
                    <div className="col-md-3">
                      {/* ENCARGADO DE CALIDAD */}
                      <label htmlFor="nombre" className="form-label">
                        <b>Estado revisión</b>
                      </label>
                      <FilterEstadoCalidad
                        onNewInput={onChangeEstadoEvaluacionCaidad}
                        defaultValue={idEntCalEst}
                      />
                    </div>

                    {/* OBSERVACIONES */}
                    <div className="col-md-6">
                      <label htmlFor="nombre" className="form-label">
                        <b>Observaciones/Acciones correctivas</b>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Escribe aqui"
                        value={obsAccEntCal}
                        onChange={onChangeObservacionesEvaluacionCalidad}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              {/* DATOS DE CALIDAD */}
              {/* <div className="card d-flex mt-4">
                <h6 className="card-header">Datos de calidad</h6>
                <div className="card-body">
                  <CardAtributosCalidadEntrada
                    dataEntradaStockCalidad={dataEntradaStockCalidad}
                    onChangeValoresAlfanumericos={onChangeValoresAlfanumericos}
                  />
                </div>
              </div> */}

              {/* BOTONES DE CANCELAR Y GUARDAR */}
              <div className="btn-toolbar mt-4 mb-4">
                <button
                  type="button"
                  onClick={() => {
                    window.close()
                  }}
                  className="btn btn-secondary me-2"
                >
                  Cerrar
                </button>
                <button
                  type="submit"
                  disabled={disableButton}
                  onClick={handleGuardarAtributos}
                  className="btn btn-primary"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {loading && <div>Cargando...</div>}
      {/* FEEDBACK AGREGAR MATERIA PRIMA */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={feedbackCreate}
        autoHideDuration={6000}
        onClose={handleCloseFeedback}
      >
        <Alert
          onClose={handleCloseFeedback}
          severity={style_message}
          sx={{ width: '100%' }}
        >
          <Typography whiteSpace={'pre-line'}>
            {feedback_description_error}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  )
}
